import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ShipmentFilter, ShipmentService } from './shipment.service';
import { UserService } from './user.service';
import {
  IconType,
  OrderStatus,
  Parcel,
  Rate,
  Shipment,
  ShipmentStatus,
  TenantStatus,
  UserStatus,
} from 'shared';
import { UtilService } from './util.service';
import * as moment from 'moment';
import { days_between } from '../utils/date.util';
import { DatePipe } from '@angular/common';
import {
  collection,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc,
  docData,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  startAfter,
  Unsubscribe,
  updateDoc,
  where,
  writeBatch,
} from '@angular/fire/firestore';
import { limit, query } from 'firebase/firestore';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class FirestoreShipmentService implements ShipmentService {
  constructor(
    private firestore: Firestore,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private utilService: UtilService,
    private userService: UserService,
    private datePipe: DatePipe,
    private authService: AuthService
  ) {}

  public changes = {};

  lastVisible;

  public list(filter: ShipmentFilter): Observable<Shipment[]> {
    const tenantId$ = from(this.authService.getTenantId());

    return tenantId$.pipe(
      switchMap((clientId) => {
        return this.afs
          .collection('clients')
          .doc(clientId)
          .collection('shipments', (queryFn) => {
            // let query: firebase.firestore.CollectionReference | firebase.firestore.Query = queryFn;
            if (filter.shipmentStatus) {
              queryFn.where('shipmentStatus', 'in', filter.shipmentStatus);
            }
            if (filter.planShipDate) {
              return filter.planShipDate.reduce(
                (q, f) => q.where('planShipDate', f.operator, f.value),
                queryFn
              );
            }
            if (filter.orderId) {
              queryFn.where(`orders`, 'array-contains', filter.orderId);
            }
            if (filter.deliveryId) {
              queryFn.where(`deliveries`, 'array-contains', filter.deliveryId);
            }
            // queryFn.where("isDeleted", "!=", true);
            return queryFn;
          })
          .valueChanges()
          .pipe(
            map((shipments) =>
              shipments.map((shipment) => this._mapToLocal(shipment))
            )
          );
      })
    );
  }

  public async deleteShipment(shipmentId: any): Promise<void> {
    const clientId = await this.authService.getTenantId();
    return deleteDoc(
      doc(this.firestore, `clients/${clientId}/shipments/${shipmentId}`)
    );
  }

  public async updateShipmentNotification(
    shipmentId: string,
    notifyRecipient: boolean
  ): Promise<void> {
    const clientId = await this.authService.getTenantId();
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('shipments')
      .doc(shipmentId)
      .update({
        notifyRecipient: notifyRecipient,
      });
  }

  /**
   * move shipment .
   * @param fromTenantId  object
   * @param fromUserId
   * @param shipmentId
   */

  public async moveShipmentToCurrentTenant(
    fromTenantId: string,
    fromUserId: string,
    shipmentId: string
  ): Promise<void> {
    const toTenantId = await this.authService.getTenantId();
    const batch = writeBatch(this.firestore);

    const shipment = await getDoc(
      doc(this.firestore, `clients/${fromTenantId}/shipments/${shipmentId}`)
    );

    batch.set(
      doc(this.firestore, `clients/${toTenantId}/shipments/${shipmentId}`),
      shipment.data()
    );
    batch.update(doc(this.firestore, `clients/${fromTenantId}`), {
      tenantStatus: TenantStatus.obsolete,
    });
    batch.update(
      doc(this.firestore, `clients/${fromTenantId}/users/${fromUserId}`),
      { userStatus: UserStatus.duplicate }
    );

    return batch.commit();
  }

  /**
   * **DEPRECATED** Use list() instead.
   * Method to perform a search for shipments.
   * @param options object that contains predetermined filter parameters.
   * fromDate, toDate, ShippingPoint, Plant and Shipment Date
   */
  public findShipments(options: any): Observable<Shipment[]> {
    const tenantId$ = from(this.authService.getTenantId());

    return tenantId$.pipe(
      switchMap((tenantId) => {
        let q = query<Shipment>(
          collection(
            this.firestore,
            `clients/${tenantId}/shipments`
          ) as CollectionReference<Shipment>
        );
        if (options.shipmentStatus) {
          q = query(q, where('shipmentStatus', 'in', options.shipmentStatus));
        }
        if (options?.deliveryDate) {
          q = query(
            q,
            where(
              'deliveryDate',
              '>=',
              options?.deliveryDate?.deliveryDateFrom._d
            )
          );
        }
        if (options?.deliveryDate) {
          q = query(
            q,
            where(
              'deliveryDate',
              '<=',
              options?.deliveryDate?.deliveryDateTo?._d
            )
          );
        }

        if (options.inThePast && options.today && options.thisWeek) {
          const end = moment(options.thisWeek._d).endOf('week');
          q = query(q, where('planShipDate', '<', end.toDate()));
        } else if (options.inThePast && options.today) {
          const start = moment().endOf('day');
          q = query(q, where('planShipDate', '<', start.toDate()));
        } else if (options.inThePast && options.thisWeek) {
          const end = moment(options.thisWeek._d).endOf('week');
          q = query(q, where('planShipDate', '<', end.toDate()));
        } else if (options.today && options.thisWeek) {
          const start = moment().startOf('week');
          const end = moment(options.thisWeek._d).endOf('week');
          q = query(q, where('planShipDate', '>=', start.toDate()));
          q = query(q, where('planShipDate', '<=', end.toDate()));
        } else if (options.inThePast) {
          const start = moment().startOf('day');
          q = query(q, where('planShipDate', '<', start.toDate()));
        } else if (options.today) {
          const start = moment().startOf('day');
          const end = moment().endOf('day');
          q = query(q, where('planShipDate', '>=', start.toDate()));
          q = query(q, where('planShipDate', '<=', end.toDate()));
        } else if (options.thisWeek) {
          const start = moment().startOf('week');
          const end = moment(options.thisWeek._d).endOf('week');
          q = query(q, where('planShipDate', '>=', start.toDate()));
          q = query(q, where('planShipDate', '<=', end.toDate()));
        }

        if (options.sortBy && !options.limit) {
          q = query(
            q,
            orderBy(
              options.sortBy,
              options.direction ? options.direction : 'asc'
            )
          );
        }
        if (options.limit && this.lastVisible && !options?.deliveryDate) {
          if (options.sortBy === 'planShipDate') {
            q = query(
              q,
              orderBy(options.sortBy, options.direction),
              orderBy('shipmentId', options.direction),
              limit(options.limit),
              startAfter(
                this.lastVisible.planShipDate,
                this.lastVisible.shipmentId
              )
            );
          } else {
            q = query(
              q,
              orderBy(options.sortBy, options.direction),
              orderBy('shipmentId', options.direction),
              limit(options.limit),
              startAfter(
                (this.lastVisible.changed_at, this.lastVisible.shipmentId)
              )
            );
          }
        }
        return collectionData<Shipment>(q).pipe(
          map((shipments) => {
            console.log(`Calling findShipments method`);
            if (shipments[shipments.length - 1]) {
              this.lastVisible = shipments[shipments.length - 1];
            }
            return shipments.map((shipment) => this._mapToLocal(shipment));
          })
        );
      })
    );
  }

  public async subscribeListShipmentsBy(
    options: any,
    listener: Subject<Shipment[]>
  ): Promise<Unsubscribe> {
    const clientId = await this.authService.getTenantId();
    let q = query<Shipment>(
      collection(
        this.firestore,
        `clients/${clientId}/shipments`
      ) as CollectionReference<Shipment>
    );
    if (options.shipmentStatus) {
      q = query(q, where('shipmentStatus', 'in', options.shipmentStatus));
    }
    if (options?.deliveryDate) {
      q = query(
        q,
        where('deliveryDate', '>=', options?.deliveryDate?.deliveryDateFrom._d)
      );
    }
    if (options?.deliveryDate) {
      q = query(
        q,
        where('deliveryDate', '<=', options?.deliveryDate?.deliveryDateTo?._d)
      );
    }

    if (options.inThePast && options.today && options.thisWeek) {
      const end = moment(options.thisWeek._d).endOf('week');
      q = query(q, where('planShipDate', '<', end.toDate()));
    } else if (options.inThePast && options.today) {
      const start = moment().endOf('day');
      q = query(q, where('planShipDate', '<', start.toDate()));
    } else if (options.inThePast && options.thisWeek) {
      const end = moment(options.thisWeek._d).endOf('week');
      q = query(q, where('planShipDate', '<', end.toDate()));
    } else if (options.today && options.thisWeek) {
      const start = moment().startOf('week');
      const end = moment(options.thisWeek._d).endOf('week');
      q = query(q, where('planShipDate', '>=', start.toDate()));
      q = query(q, where('planShipDate', '<=', end.toDate()));
    } else if (options.inThePast) {
      const start = moment().startOf('day');
      q = query(q, where('planShipDate', '<', start.toDate()));
    } else if (options.today) {
      const start = moment().startOf('day');
      const end = moment().endOf('day');
      q = query(q, where('planShipDate', '>=', start.toDate()));
      q = query(q, where('planShipDate', '<=', end.toDate()));
    } else if (options.thisWeek) {
      const start = moment().startOf('week');
      const end = moment(options.thisWeek._d).endOf('week');
      q = query(q, where('planShipDate', '>=', start.toDate()));
      q = query(q, where('planShipDate', '<=', end.toDate()));
    }

    if (options.sortBy && !options.limit) {
      q = query(
        q,
        orderBy(options.sortBy, options.direction ? options.direction : 'asc')
      );
    }
    if (options.limit && this.lastVisible && !options?.deliveryDate) {
      if (options.sortBy === 'planShipDate') {
        q = query(
          q,
          orderBy(options.sortBy, options.direction),
          orderBy('shipmentId', options.direction),
          limit(options.limit),
          startAfter(this.lastVisible.planShipDate, this.lastVisible.shipmentId)
        );
      } else {
        q = query(
          q,
          orderBy(options.sortBy, options.direction),
          orderBy('shipmentId', options.direction),
          limit(options.limit),
          startAfter((this.lastVisible.changed_at, this.lastVisible.shipmentId))
        );
      }
    }
    return onSnapshot(
      q,
      (querySnapshot) => {
        const shipments = [];
        querySnapshot.forEach((_doc) => {
          shipments.push(this._mapToLocal(_doc.data()));
        });
        if (shipments[shipments.length - 1]) {
          this.lastVisible = shipments[shipments.length - 1];
        }
        listener.next(shipments);
      },
      (error) => console.log(error)
    );
  }

  // One of these methods should be deleted
  public async subscribeToShipmentUpdates(
    id: string,
    listener: Subject<Shipment>
  ): Promise<Unsubscribe> {
    const clientId = await this.authService.getTenantId();

    return onSnapshot(
      doc(this.firestore, `clients/${clientId}/shipments/${id}`),
      (querySnapshot) => {
        listener.next(querySnapshot.data() as Shipment);
      },
      (error) => console.log(error)
    );
  }

  // One of these methods should be deleted
  public async subscribeShipmentById(
    id: string,
    listener: Subject<Shipment>
  ): Promise<Unsubscribe> {
    const clientId = await this.authService.getTenantId();
    return onSnapshot(
      doc(this.firestore, `clients/${clientId}/shipments/${id}`),
      (querySnapshot) => {
        console.log(
          'Retrieve the data from DB, for subscribeShipmentById subs'
        );
        listener.next(this._mapToLocal(querySnapshot.data()));
      }
    );
  }

  async findShipmentByClientId(clientId, shipmentId: string) {
    const shipmentDocSnapshot = await getDoc(
      doc(this.firestore, `clients/${clientId}/shipments/${shipmentId}`)
    );
    return this._mapToLocal(shipmentDocSnapshot.data());

    // return this
    //   .afs
    //   .collection('clients')
    //   .doc(clientId)
    //   .collection('shipments')
    //   .doc(shipmentId)
    //   .valueChanges()
    //   .pipe(
    //     // filter(shipment => !!shipment),
    //     tap(shipment => console.log(`Calling 'findShipmentByClientId' service for shipment ${shipment.shipmentId}`)),
    //     map(shipment => this._mapToLocal(shipment)),
    //   );
  }

    findShipmentById(id: string): Observable<any> {
        const tenantId$ = from(this.authService.getTenantId());
        return tenantId$.pipe(
            switchMap((tenantId) => {
                return docData(
                    doc(this.firestore, `clients/${tenantId}/shipments/${id}`)
                );
            }),
            map(shipment => this._mapToLocal(shipment))
        );
    }

  private _mapToLocal(shipment: any): Shipment {
    // Replace the below code with a better logic (dynamic one)
    const getShipmentStatusDesc = (id: ShipmentStatus): string => {
      let desc = '';
      switch (id) {
        case 'NEW':
          desc = 'New';
          break;
        case 'PACKED':
          desc = 'Packed';
          break;
        case 'BOOKING_INITIATED':
          desc = 'Booking Initiated';
          break;
        case 'LABEL_GENERATED':
          desc = 'Label Generated';
          break;
        case 'IN_TRANSIT':
          desc = 'Transit';
          break;
        case 'PENDING_REFUND':
          desc = 'Pending Refund';
          break;
        case 'DELIVERED':
          desc = 'Delivered';
          break;
      }
      return desc;
    };
    const obj = {} as Shipment;
    obj.shipmentId = shipment.shipmentId;
    obj.createdOn = shipment.createdOn?.toDate();
    obj.createdBy = shipment.createdBy;
    obj.shipmentStatus = shipment.shipmentStatus;
    obj.shipmentStatusDesc =
      getShipmentStatusDesc(shipment.shipmentStatus) || shipment.shipmentStatus;
    obj.planShipDate = shipment.planShipDate
      ? shipment.planShipDate.toDate()
      : undefined;
    obj.deliveryDate = shipment.deliveryDate
      ? shipment.deliveryDate.toDate()
      : undefined;
    obj.shipFrom = shipment.shipFrom;
    obj.shipTo = shipment.shipTo;
    obj.deliveries = shipment.deliveries;
    obj.parcels = shipment.parcels;
    obj.rates = shipment.rates;
    obj.selectedRate = shipment.selectedRate;
    obj.carrierShipmentId = shipment.carrierShipmentId;
    obj.carrierTrackingId = shipment.carrierTrackingId;
    obj.carrierTrackingUrl = shipment.carrierTrackingUrl;
    obj.carrierLabelUrl = shipment.carrierLabelUrl;
    obj.workflowStatus = shipment.workflowStatus;
    obj.tracking = shipment.tracking;
    if (obj.tracking) {
      obj.tracking.eta = shipment.tracking?.eta?.toDate();
      obj.tracking.originalEta = shipment.tracking?.originalEta?.toDate();
      obj.tracking.currentStatus = shipment.tracking?.currentStatus;
    }
    if (obj.tracking?.currentStatus) {
      obj.tracking.currentStatus.statusDate =
        shipment?.tracking?.currentStatus?.statusDate.toDate();
    }
    obj.items = shipment.items;
    obj.rateError = shipment.rateError;
    switch (shipment.shipmentStatus) {
      case ShipmentStatus.new:
        obj.shipmentStatusIcon = 'note';
        obj.shipmentStatusIconType = ShipmentStatus.new;
        obj.shipmentStatusDescription = '';
        break;
      case ShipmentStatus.packed:
        obj.shipmentStatusIcon = 'shopping_bag';
        obj.shipmentStatusIconType = ShipmentStatus.packed;
        obj.shipmentStatusDescription = `${obj.parcels[0].height}" X ${obj.parcels[0].width}" X ${obj.parcels[0].length}" - ${obj.parcels[0].weight} ${obj.parcels[0].weightUnit}`;
        break;
      case ShipmentStatus.booking_initiated:
        obj.shipmentStatusIcon = 'money';
        obj.shipmentStatusIconType = ShipmentStatus.booking_initiated;
        obj.shipmentStatusDescription = `${obj.selectedRate.carrier} - ${obj.selectedRate.serviceLevelName} `;
        break;
      case ShipmentStatus.label_generated:
        obj.shipmentStatusIcon = 'label_outline';
        obj.shipmentStatusIconType = ShipmentStatus.label_generated;
        break;
      case ShipmentStatus.pending_refund:
        obj.shipmentStatusIcon = 'label_outline';
        obj.shipmentStatusIconType = ShipmentStatus.pending_refund;
        break;
      case ShipmentStatus.in_transit:
        obj.shipmentStatusIcon = 'local_shipping';
        obj.shipmentStatusIconType = ShipmentStatus.in_transit;
        const days = obj?.tracking?.originalEta
          ? days_between(obj?.tracking?.originalEta)
          : days_between(obj?.tracking?.eta);
        const date = this.datePipe.transform(
          (obj?.tracking?.originalEta
            ? obj?.tracking?.originalEta
            : obj?.tracking?.eta
          )?.getTime(),
          'MM/d'
        );
        // obj.shipmentStatusDescription = `ETA ${days} days - ${date}`;
        obj.shipmentStatusDescription = `ETA ${date} (${days} days)`;
        // obj.carrierTrackingId = null;
        break;
      case ShipmentStatus.delivered:
        obj.shipmentStatusIcon = 'check_circle_outline';
        obj.shipmentStatusIconType = ShipmentStatus.delivered;
        obj.shipmentStatusDescription = `On ${this.datePipe.transform(
          (obj?.tracking?.originalEta
            ? obj?.tracking?.originalEta
            : obj?.tracking?.eta
          )?.getTime(),
          'EEE, MMM d, hh:mm a'
        )}`;
        // obj.carrierTrackingId = null;
        break;
      default:
        obj.shipmentStatusIcon = '';
        obj.shipmentStatusIconType = IconType.info;
        break;
    }
    obj.planShipDateDaysLeft = this.utilService.getNumberOfDaysUpto(
      obj.planShipDate
    );
    if (obj.planShipDateDaysLeft > 5) {
      if (shipment.orderStatus === OrderStatus.new) {
        obj.planShipDateDesc = `Due in ${obj.planShipDateDaysLeft} days`;
        obj.planShipDateDescSmall = `${obj.planShipDateDaysLeft} days`;
        obj.planShipDateIcon = 'alarm';
        obj.planShipDateIconType = IconType.info;
      } else {
        obj.planShipDateDesc = `In ${obj.planShipDateDaysLeft} days`;
        obj.planShipDateDescSmall = `${obj.planShipDateDaysLeft} days`;
        obj.planShipDateIcon = 'check_circle_outline';
        obj.planShipDateIconType = IconType.info;
      }
    } else if (obj.planShipDateDaysLeft < 5 && obj.planShipDateDaysLeft > 0) {
      if (shipment.orderStatus === OrderStatus.new) {
        obj.planShipDateDesc = `Due in ${obj.planShipDateDaysLeft} days`;
        obj.planShipDateDescSmall = `${obj.planShipDateDaysLeft} days`;
        obj.planShipDateIcon = 'alarm';
        obj.planShipDateIconType = IconType.warn;
      } else {
        obj.planShipDateDesc = `In ${obj.planShipDateDaysLeft} days`;
        obj.planShipDateDescSmall = `${obj.planShipDateDaysLeft} days`;
        obj.planShipDateIcon = 'check_circle_outline';
        obj.planShipDateIconType = IconType.info;
      }
    } else if (obj.planShipDateDaysLeft === 0) {
      if (shipment.orderStatus === OrderStatus.new) {
        obj.planShipDateDesc = `Due today`;
        obj.planShipDateDescSmall = `Today`;
        obj.planShipDateIcon = 'warning_amber';
        obj.planShipDateIconType = IconType.warn;
      } else {
        obj.planShipDateDesc = `Today`;
        obj.planShipDateDescSmall = `Today`;
        obj.planShipDateIcon = 'check_circle_outline';
        obj.planShipDateIconType = IconType.info;
      }
    } else if (obj.planShipDateDaysLeft === -1) {
      if (shipment.orderStatus === OrderStatus.new) {
        obj.planShipDateDesc = `${Math.abs(
          obj.planShipDateDaysLeft
        )} day past due`;
        obj.planShipDateDescSmall = `${obj.planShipDateDaysLeft} day`;
        obj.planShipDateIcon = 'error_outline';
        obj.planShipDateIconType = IconType.error;
      } else {
        obj.planShipDateDesc = `${Math.abs(obj.planShipDateDaysLeft)} day ago`;
        obj.planShipDateDescSmall = `${obj.planShipDateDaysLeft} day`;
        obj.planShipDateIcon = 'check_circle_outline';
        obj.planShipDateIconType = IconType.info;
      }
    } else {
      if (shipment.orderStatus === OrderStatus.new) {
        obj.planShipDateDesc = `${Math.abs(
          obj.planShipDateDaysLeft
        )} days past due`;
        obj.planShipDateDescSmall = `${obj.planShipDateDaysLeft} days`;
        obj.planShipDateIcon = 'error_outline';
        obj.planShipDateIconType = IconType.error;
      } else {
        obj.planShipDateDesc = `${Math.abs(obj.planShipDateDaysLeft)} days ago`;
        obj.planShipDateDescSmall = `${obj.planShipDateDaysLeft} days`;
        obj.planShipDateIcon = 'check_circle_outline';
        obj.planShipDateIconType = IconType.info;
      }
    }
    obj.deliveryDateDaysLeft = this.utilService.getNumberOfDaysUpto(
      obj.deliveryDate
    );
    if (obj.deliveryDateDaysLeft > 5) {
      if (shipment.orderStatus === OrderStatus.new) {
        obj.deliveryDateDesc = `Due in ${obj.deliveryDateDaysLeft} days`;
        obj.deliveryDateDescSmall = `${obj.deliveryDateDaysLeft} days`;
        obj.deliveryDateIcon = 'alarm';
        obj.deliveryDateIconType = IconType.info;
      } else {
        obj.deliveryDateDesc = `In ${obj.deliveryDateDaysLeft} days`;
        obj.deliveryDateDescSmall = `${obj.deliveryDateDaysLeft} days`;
        obj.deliveryDateIcon = 'check_circle_outline';
        obj.deliveryDateIconType = IconType.info;
      }
    } else if (obj.deliveryDateDaysLeft < 5 && obj.deliveryDateDaysLeft > 0) {
      if (shipment.orderStatus === OrderStatus.new) {
        obj.deliveryDateDesc = `Due in ${obj.deliveryDateDaysLeft} days`;
        obj.deliveryDateDescSmall = `${obj.deliveryDateDaysLeft} days`;
        obj.deliveryDateIcon = 'alarm';
        obj.deliveryDateIconType = IconType.warn;
      } else {
        obj.deliveryDateDesc = `In ${obj.deliveryDateDaysLeft} days`;
        obj.deliveryDateDescSmall = `${obj.deliveryDateDaysLeft} days`;
        obj.deliveryDateIcon = 'check_circle_outline';
        obj.deliveryDateIconType = IconType.info;
      }
    } else if (obj.deliveryDateDaysLeft === 0) {
      if (shipment.orderStatus === OrderStatus.new) {
        obj.deliveryDateDesc = `Due today`;
        obj.deliveryDateDescSmall = `Today`;
        obj.deliveryDateIcon = 'warning_amber';
        obj.deliveryDateIconType = IconType.warn;
      } else {
        obj.deliveryDateDesc = `Today`;
        obj.deliveryDateDescSmall = `Today`;
        obj.deliveryDateIcon = 'check_circle_outline';
        obj.deliveryDateIconType = IconType.info;
      }
    } else if (obj.deliveryDateDaysLeft === -1) {
      if (shipment.orderStatus === OrderStatus.new) {
        obj.deliveryDateDesc = `${Math.abs(
          obj.deliveryDateDaysLeft
        )} day past due`;
        obj.deliveryDateDescSmall = `${obj.deliveryDateDaysLeft} day`;
        obj.deliveryDateIcon = 'error_outline';
        obj.deliveryDateIconType = IconType.error;
      } else {
        obj.deliveryDateDesc = `${Math.abs(obj.deliveryDateDaysLeft)} day ago`;
        obj.deliveryDateDescSmall = `${obj.deliveryDateDaysLeft} day`;
        obj.deliveryDateIcon = 'check_circle_outline';
        obj.deliveryDateIconType = IconType.info;
      }
    } else {
      if (shipment.orderStatus === OrderStatus.new) {
        obj.deliveryDateDesc = `${Math.abs(
          obj.deliveryDateDaysLeft
        )} days past due`;
        obj.deliveryDateDescSmall = `${obj.deliveryDateDaysLeft} days`;
        obj.deliveryDateIcon = 'error_outline';
        obj.deliveryDateIconType = IconType.error;
      } else {
        obj.deliveryDateDesc = `${Math.abs(obj.deliveryDateDaysLeft)} days ago`;
        obj.deliveryDateDescSmall = `${obj.deliveryDateDaysLeft} days`;
        obj.deliveryDateIcon = 'check_circle_outline';
        obj.deliveryDateIconType = IconType.info;
      }
    }

    obj.insurance = shipment.insurance;
    obj.signatureConfirmation = shipment.signatureConfirmation;
    obj.notifyRecipient = shipment.notifyRecipient;
    obj.labelFileType = shipment.labelFileType;
    return obj;
  }

  /**
   * Method to add a shipment to the DB.
   * Validation of the deliveries is done. The same shipment details should be present.
   * The ID is generated and then the object is transformed to the required format.
   *
   * @param deliveries the list of deliveries to create a shipment.
   */

  public async createShipment(shipment): Promise<string> {
    // return new Promise((resolve, reject) => {

    //   this.client
    //     .collection('shipments').doc(shipment.shipmentId)
    //     .set(shipment)
    //     .then(() => { resolve(shipment.shipmentId); })
    //     .catch(error => reject(error.message));
    // });
    const clientId = await this.authService.getTenantId();
    const batch = this.afs.firestore.batch();
    // Create shipment.
    batch.set(
      await this.afs
        .collection('clients')
        .doc(clientId)
        .collection('shipments')
        .doc(shipment.shipmentId).ref,
      shipment
    );

    // Update deliveries with shipment id.
    shipment.deliveries.forEach(async (delivery) => {
      const deliveryFields = {
        shipmentId: shipment.shipmentId,
      };
      batch.update(
        await this.afs
          .collection('clients')
          .doc(clientId)
          .collection('deliveries')
          .doc(delivery).ref,
        deliveryFields
      );
    });

    return batch
      .commit()
      .then((res) => shipment.shipmentId)
      .catch((err) => {
        console.log(`Error occurred during batch commit: ${err}`);
        return 'Error occurred during shipment creation';
      });
  }

  async updatePackedStatus(
    shipmentId: string,
    parcels: Parcel[]
  ): Promise<void> {
    const clientId = await this.authService.getTenantId();
    return updateDoc(
      doc(this.firestore, `clients/${clientId}/shipments/${shipmentId}`),
      {
        parcels,
        shipmentStatus: ShipmentStatus.packed,
        rates: [],
      }
    );
  }

  async updateShipmentSelectedRate(
    shipmentId: string,
    rate: Rate
  ): Promise<void> {
    const clientId = await this.authService.getTenantId();
    return updateDoc(
      doc(this.firestore, `clients/${clientId}/shipments/${shipmentId}`),
      {
        selectedRate: rate,
        shipmentStatus: ShipmentStatus.booking_initiated,
        tracking: {
          carrier: rate.carrier || ''
        }
      }
    );
  }

  async cancelBooking(shipmentId: string): Promise<void> {
    const clientId = await this.authService.getTenantId();
    return updateDoc(
      doc(this.firestore, `clients/${clientId}/shipments/${shipmentId}`),
      {
        selectedRate: null,
        shipmentStatus: ShipmentStatus.packed,
        rateError: '',
      }
    );
  }

  async emptyRate(shipmentId: string): Promise<void> {
    const clientId = await this.authService.getTenantId();
    return updateDoc(
      doc(this.firestore, `clients/${clientId}/shipments/${shipmentId}`),
      { rates: null }
    );
  }

  async update(shipmentId: string, changes: any): Promise<void> {
    const clientId = await this.authService.getTenantId();
    return updateDoc(
      doc(this.firestore, `clients/${clientId}/shipments/${shipmentId}`),
      changes
    );
  }

  async get(shipmentId): Promise<Shipment> {
    const tenantId = await this.authService.getTenantId();
    return this._mapToLocal((await getDoc(
      doc(this.firestore, `clients/${tenantId}/shipments/${shipmentId}`)
    )).data());
  }
}
