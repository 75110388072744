import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { Role, Shipment, ShipmentStatus, TenantStatus, UserCreationTrigger, UserStatus } from 'shared';
import { generateShipmentId } from '../utils/generator.util';
import { UserService } from './user.service';
import { BehaviorSubject } from 'rxjs';
import { Auth } from '@angular/fire/auth';
import { doc, Firestore, getDoc, setDoc, updateDoc } from '@angular/fire/firestore';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LabelService {

  constructor(private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private auth: Auth,
    private firestore: Firestore,
    private userService: UserService,
    private authService: AuthService,
  ) { }

  shipment = {} as Shipment;
  public shipmentObject$: BehaviorSubject<Shipment> = new BehaviorSubject<Shipment>({} as Shipment);

  getShipment() {
    return this.shipment;
  }

  getShipmentObservable() {
    return this.shipmentObject$.asObservable();
  }

  setShipment(shipment) {
    this.shipmentObject$.next(shipment);
    this.shipment = shipment;
  }


  /**
   * Create a new shipment in the database.
   * @param shipment shipment object
   */
  async createShipment(shipment) {
    let clientId: string;
    let email: string;
    if (this.authService.isSignedIn()) {
      clientId = await this.authService.getTenantId();
      email = this.auth.currentUser.email;
    } else {
      const { tenantId } = await this.userService.addGuest({
        roles: [Role.admin],
        trigger: UserCreationTrigger.promo_link,
      });
      clientId = tenantId;
      email = '';
    }
    const obj = {
      shipmentId: generateShipmentId(),
      shipmentStatus: ShipmentStatus.new,
      planShipDate: new Date(),
      deliveryDate: new Date(),
      shipFrom: shipment.shipFrom,
      shipTo: shipment.shipTo,
      items: shipment.items ? shipment.items : [],
      createdBy: email,
      createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      // insurance: shipment.insurance ? shipment.insurance : {},
      // signatureConfirmation: shipment.signatureConfirmation,
      // labelFileType: shipment.labelFileType,
      // saveDefault: shipment.saveDefault,
      // parcels: shipment.parcels,
      notifyRecipient: shipment.notifyRecipient
    };

    await setDoc(doc(this.firestore, `clients/${clientId}/shipments/${obj.shipmentId}`), obj);
    return obj.shipmentId;
  }

  /**
   * update shipment
   *
   */
  async updateShipment(shipment) {
    let clientId: string;

    if (this.authService.isSignedIn()) {
      clientId = await this.authService.getTenantId();
    } else {
      clientId = localStorage.getItem('tenantId');
    }

    return this.afs.collection('clients')
      .doc(clientId)
      .collection('shipments')
      .doc(shipment.shipmentId)
      .update(shipment);
  }

  /**
   * Update existing shipment in the database.
   * @param shipment shipment object
   */
  async updateShipmentOption(shipment): Promise<void> {
    const clientId = await this.authService.getTenantId();
    return this
      .afs
      .collection('clients')
      .doc(clientId)
      .collection('shipments')
      .doc(shipment.shipmentId)
      .update({
        insurance: shipment.insurance ? shipment.insurance : {},
        signatureConfirmation: shipment.signatureConfirmation,
        labelFileType: shipment.labelFileType,
        saveDefault: shipment.saveDefault,
        shipmentStatus: ShipmentStatus.packed
      });
  }

  /**
   * Create a update Email in the database.
   * @param shipmentId string object
   * @param email string object
   */
  async updateGuestEmail(shipmentId: string, email): Promise<void> {
    const clientId = await this.authService.getTenantId();
    return this
      .afs
      .collection('clients')
      .doc(clientId)
      .collection('shipments')
      .doc(shipmentId)
      .update({
        createdBy: email
      });
  }

  /**
   * Create a get Default Address in the database.
   */
  public async getDefaultAddress() {

    const clientId = await this.authService.getTenantId();
    const userId = await this.authService.getUserId();

    try {
      return (await (getDoc<any>(doc(this.firestore, `clients/${clientId}/users/${userId}`)))).data();
    } catch (error) {
      console.log(`Error fetching sender default address from database: ${error}`);
    }
  }

  /**
   * Create a address in the database.
   * @param shipmentAddress  object
   */

  public async createAddress(shipmentAddress): Promise<any> {
    const clientId = await this.authService.getTenantId();
    const userId = await this.authService.getUserId();

    try {
      await updateDoc(
        doc(this.firestore, `clients/${clientId}/users/${userId}`),
        { defaultAddress: shipmentAddress });
    } catch (error) {
      console.log(`Error writing sender default address to database: ${error}`);
    }

  }

  /**
   * getDefaultOptions
   */
  public async getDefaultOptions(): Promise<any> {
    const clientId = await this.authService.getTenantId();
    const userId = await this.authService.getUserId();
    return getDoc(doc(this.firestore, `clients/${clientId}/users/${userId}`));
  }

  /**
   * Create a create options default in the database.
   * @param DefaultOptions  object
   */
  public async setSelectOptionDefault(DefaultOptions): Promise<any> {
    const clientId = await this.authService.getTenantId();
    const userId = await this.authService.getUserId();
    if (userId) {
      return updateDoc(doc(this.firestore, `clients/${clientId}/users/${userId}`), { defaultOptions: DefaultOptions });
    }
  }

  /**
   * move shipment .
   * @param tenantId  object
   * @param shipment object
   */

  public async moveShipment(tenantId: string, uid: string, shipmentId: string): Promise<string> {
    const userClientId = await this.authService.getTenantId();

    return await firebase.firestore().collection('clients')
      .doc(tenantId)
      .collection('shipments')
      .doc(shipmentId)
      .get().then(async res => {
        const shipment = res.data();
        const batch = this.afs.firestore.batch();
        batch.set(
          this.afs.collection('clients')
            .doc(userClientId)
            .collection('shipments')
            .doc(res.id).ref,
          shipment);

        // Update the client
        batch.update(this.afs.collection('clients')
          .doc(tenantId).ref, { tenantStatus: TenantStatus.obsolete }
        );

        // Update the user
        batch.update(this.afs.collection('clients')
          .doc(tenantId)
          .collection('users')
          .doc(uid).ref,
          { userStatus: UserStatus.duplicate }
        );

        return batch.commit()
          .then(res => shipment.shipmentId)
          .catch(err => {
            console.log(`Error occurred during moving shipment: ${err}`);
            return 'Error occurred during moving shipment';
          });
      });
  }

}
