import {animate, animation, keyframes, style, transition, trigger, useAnimation} from '@angular/animations';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router, RoutesRecognized} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Role, Shipment} from 'shared';
import {LabelService} from './core/services/label.service';
import {SharedDataService} from './core/services/shared-data.service';
import {UserService} from './core/services/user.service';
import {UserProfileDialogComponent} from './user-profile-dialog/user-profile-dialog.component';
import {Auth, user} from '@angular/fire/auth';
import {AuthService} from './core/services/auth.service';

export const BOUNCE = animation(
  animate('{{ duration }}',
    keyframes([
      style({
        transformOrigin: 'center bottom',
        animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
        transform: 'translate3d(0,0,0)',
        offset: 0
      }),
      style({
        transformOrigin: 'center bottom',
        animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
        transform: 'translate3d(0,0,0)',
        offset: .2
      }),
      style({
        transformOrigin: 'center bottom',
        animationTimingFunction: 'cubic-bezier(0.755, 0.050, 0.855, 0.060)',
        transform: 'translate3d(0, -30px, 0)',
        offset: .4
      }),
      style({
        transformOrigin: 'center bottom',
        animationTimingFunction: 'cubic-bezier(0.755, 0.050, 0.855, 0.060)',
        transform: 'translate3d(0, -30px, 0)',
        offset: .43
      }),
      style({
        transformOrigin: 'center bottom',
        animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
        transform: 'translate3d(0,0,0)',
        offset: .53
      }),
      style({
        transformOrigin: 'center bottom',
        animationTimingFunction: 'cubic-bezier(0.755, 0.050, 0.855, 0.060)',
        transform: 'translate3d(0, -15px, 0)',
        offset: .7
      }),
      style({
        transformOrigin: 'center bottom',
        animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
        transform: 'translate3d(0,0,0)',
        offset: .8
      }),
      style({transformOrigin: 'center bottom', transform: 'translate3d(0, -4px, 0)', offset: .9}),
      style({
        transformOrigin: 'center bottom',
        animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
        transform: 'translate3d(0,0,0)',
        offset: 1
      }),
    ])
  ),
  {
    params: {
      duration: '1s'
    }
  }
);

enum Action {
  shipments = 'shipments',
  users = 'users'
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('cartState', [
      transition('inactive => active', [
        useAnimation(BOUNCE)
      ]),
    ])
  ]
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    // private afAuth: AngularFireAuth,
    private auth: Auth,
    private dialog: MatDialog,
    private data: SharedDataService,
    private userService: UserService,
    private router: Router,
    private labelService: LabelService,
    private authService: AuthService,
  ) {
    router.events
      .pipe(filter(event => event instanceof RoutesRecognized))
      .subscribe((event) => {
        const e: any = event;
        this.data.setRedirectUrl(decodeURIComponent(e.url));
      });
  }

  get show() {
    if (this.router.url.indexOf('passwordLess') > -1) {
      return false;
    } else if (this.router.url.indexOf('login') > -1) {
      return false;
    } else if (this.router.url.indexOf('checkEmail') > -1) {
      return false;
    } else if (this.router.url.indexOf('register') > -1) {
      return false;
    } else if (this.router.url.indexOf('onboardingError') > -1) {
      return false;
    } else if (this.router.url.indexOf('signinLinkExpiredError') > -1) {
      return false;
    } else if (this.router.url.indexOf('forgot-password') > -1) {
      return false;
    } else {
      return true;
    }
  }

  get urlText() {
    const getUrl = this.data.getRedirectUrl();
    let setUrl = '';
    this.breadCrumb = '';
    if (getUrl?.indexOf('shipments') > -1) {
      if (getUrl?.indexOf('shipments/detail') > -1) {
        this.breadCrumb = 'shipmentList';
      }
      setUrl = 'Shipments';
    } else if (getUrl?.indexOf('/label') > -1) {
      setUrl = 'Create Label';
    } else if (getUrl?.indexOf('dashboard') > -1) {
      setUrl = 'Dashboard';
    } else if (getUrl?.indexOf('setting') > -1) {
      if (getUrl?.indexOf('setting/editUser') > -1) {
        this.breadCrumb = 'userList';
      }
      setUrl = 'Settings';
    } else if (getUrl?.indexOf('transactionHistory') > -1) {
      setUrl = 'Track Shipment';
    }
    return setUrl;
  }


  get shipmentUrl() {
    const url = this.data.getRedirectUrl();
    return url?.indexOf('shipments') > -1;
  }

  get shipmentLabelUrl() {
    const url = this.data.getRedirectUrl();
    return url?.indexOf('shipments/label') > -1;
  }

  get dashboardUrl() {
    const url = this.data.getRedirectUrl();
    return url?.indexOf('dashboard') > -1;
  }

  get settingUrl() {
    const url = this.data.getRedirectUrl();
    return url?.indexOf('setting') > -1;
  }

  get trackingUrl() {
    const url = this.data.getRedirectUrl();
    return url?.indexOf('transactionHistory') > -1;
  }

  public cartItemsCount: number;
  public $isUserLoggedIn: Observable<boolean> = user(this.auth).pipe(map(_user => !!_user));

  private destroy$: Subject<boolean> = new Subject<boolean>();

  panelOpenState = true;

  isAdmin = false;
  isShipper = false;

  actions: { name: '', url: '' };
  breadCrumb = '';

  async ngOnInit() {
    this.confirmSignIn();
    // this.cartService
    //   .list()
    //   .pipe(
    //     takeUntil(this.destroy$),
    //     map(cartItems => cartItems.length)
    //   )
    //   .subscribe(value => {
    //     this.cartItemsCount = value;
    //   });
  }

  confirmSignIn() {
    this.$isUserLoggedIn.subscribe(async res => {
      if (res) {
        this.isAdmin = await this.authService.hasRole(Role.admin);
        !this.isAdmin ? this.isShipper = await this.authService.hasRole(Role.shipper) : null;
      }
    });
  }

  accountClick() {
    this.dialog.open(UserProfileDialogComponent, {'position': {'top': '70px', 'right': '10px'}});
  }

  sendFeedback() {
    let feedbackLink = `https://gn3grycct6u.typeform.com/to/utd4E1rD`;
    if (this.auth.currentUser) {
      // TODO rework it with env properties
      feedbackLink = `https://gn3grycct6u.typeform.com/to/utd4E1rD#email=${this.auth.currentUser.email}`;
      window.open(feedbackLink, '_blank');
      return;
    }
    window.open(feedbackLink, '_blank');
  }

  // onCart() {
  //   this.dialog.open(CartDialogComponent, { position: { top: '70px', right: '10px' }, autoFocus: false });
  // }

  onLabel() {
    const shipment = {} as Shipment;
    this.labelService.setShipment(shipment);
    this.router.navigateByUrl('/label');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
