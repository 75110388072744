import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Auth} from '@angular/fire/auth';

@Component({
  selector: 'app-onboarding-error',
  templateUrl: './onboarding-error.component.html',
  styleUrls: ['./onboarding-error.component.css']
})
export class OnboardingErrorComponent implements OnInit {
  reportLink: string;

  constructor(
    private readonly auth: Auth,
    protected deviceDetectorService: DeviceDetectorService
  ) {
  }

  ngOnInit(): void {
    if (this.auth.currentUser) {
      this.reportLink = `https://gn3grycct6u.typeform.com/to/utd4E1rD#email=${this.auth.currentUser.email}`;
      return;
    }
    this.reportLink = `https://gn3grycct6u.typeform.com/to/utd4E1rD`;
  }

  openReportForm() {
    window.open(this.reportLink, '_blank');
  }
}
