import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  signupForm: UntypedFormGroup;

  validations = {
    firstname: [
      { type: 'required', message: 'What is your name again' }
    ],
    lastname: [
      { type: 'required', message: 'I did not get that' }
    ],
    company: [
      { type: 'required', message: 'Enter your Company Code or type in GUEST' }
    ],
    email: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Email is invalid' }
    ],
    password: [
      { type: 'required', message: 'Enter password' }
    ]
  }

  constructor(private fb: UntypedFormBuilder,
              private afAuth: AngularFireAuth,
              private snackBar: MatSnackBar,
              private router: Router) { }

  ngOnInit() {
    this.signupForm = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  signup(form: UntypedFormGroup) {
    if (form.status === 'VALID') {
      this.afAuth.createUserWithEmailAndPassword(form.value.email, form.value.password)
        .then(user => {
          console.log(user);
          this.router.navigate(['/', 'dashboard']);
        })
        .catch(error => {
          console.log("User Create Error " + error);
          let errorMessage: string;
          let action: string;
          switch (error.code) {
            case 'auth/email-already-in-use':
              errorMessage = 'Email already in use';
              action = 'Sign In';
              break;
            case 'auth/invalid-email':
              errorMessage = 'Email invalid. Try again';
              break;
            case 'auth/weak-password':
              errorMessage = 'Password too weak. Try again';
              break;
            default:
              errorMessage = 'Sign up error. Contact admin';
              break;
          }
          let snackBarRef = this.snackBar.open(errorMessage, 'Action');
          snackBarRef.onAction().subscribe(() => {
            if (action === 'Sign In') {
              this.router.navigate(['/', 'login']);
            }
          });
        });
    }
  }

}
