import { Injectable } from "@angular/core";
import { Auth, User } from "@angular/fire/auth";
import { Role } from "shared";
import { doc, Firestore, getDoc } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private readonly auth: Auth,
    private readonly firestore: Firestore
  ) {}

  /**
   * Returns client id for the signed in user from the claims.
   */
  public async getTenantId() {
    const getFromLocalStorage = () => {
      return localStorage.getItem("tenantId");
    };

    const getFromFirebaseAuthToken = async () => {
      const token = await this.auth.currentUser.getIdTokenResult();
      const clientId: string = token.claims["clientId"];
      return clientId;
    };

    const authInitPromise = new Promise<User>((resolve, reject) => {
      const unsubscribe = this.auth.onAuthStateChanged(
        (user) => {
          unsubscribe();
          return resolve(user);
        },
        (error) => {
          unsubscribe();
          reject(error);
        }
      );
    });
    return (await authInitPromise)
      ? await getFromFirebaseAuthToken()
      : getFromLocalStorage();
  }
  /**
   * Returns the shipagile userid for the signed in user.
   */
  public async getUserId() {
    const token = await this.auth.currentUser.getIdTokenResult();
    const userid: string = token.claims["userId"];
    if (!userid) {
      throw new Error(
        `User id not found for user ${this.auth.currentUser.email}`
      );
    }
    return userid;
  }

  /**
   * Returns true if the user has the role.
   * @param {Role} role Role id
   */
  public async hasRole(role: Role) {
    const token = await this.auth.currentUser.getIdTokenResult();
    const roles: Role[] = token.claims["roles"];
    return roles?.includes(role);
  }

  /**
   * Returns true if the user is signed in.
   */
  public isSignedIn() {
    return !!this.auth.currentUser;
  }

  /**
   * Returns true if the given email is in the email blacklist.
   * @param email Email to check
   * @returns True if the email is in the blacklist
   */
  public async isEmailBlacklisted(email: string) {
    if (!email) {
      return false;
    }
    const emailBlacklistDoc = doc(
      this.firestore,
      "/0GLOBAL",
      "emails_blacklist"
    );
    const emailBlacklist = await getDoc(emailBlacklistDoc);
    const blacklist: string[] = emailBlacklist.data()?.emails || [];
    return blacklist.includes(email.split("@")[1]);
  }
}
