export const environment = {
  production: false,
  emulator: false,
  firebase: {
    apiKey: "AIzaSyBc1UMXPb7ZkYRFS1GkQXxWefK9GfRhQz4",
    authDomain: "global-approach-214817.firebaseapp.com",
    databaseURL: "https://global-approach-214817.firebaseio.com",
    projectId: "global-approach-214817",
    storageBucket: "global-approach-214817.appspot.com",
    messagingSenderId: "697817037665",
    appId: "1:697817037665:web:e3cfc8b969f784a42c2320",
    measurementId: "G-T03THP191E"
  },
  stripeAPIKey: 'pk_test_51L5gs0CR23Tc4Phdmdgb66c40gnUKQs5XE9S4a5Um9Dr2DNZ37I8AdN6VFYzUMdF20cEvhzlQxHJ509XptI8yk6l00L7uM7v8S',
  googleMapKey: "AIzaSyBrUQMVQYJLkUr1BHa48KB9YkiCOVMaV1Q",
  authEmulatorPort: 9099,
  firestoreEmulatorPort: 8080,
  functionsEmulatorPort: 6001,
  recaptchaSiteKey: '6Le9Z0IlAAAAAGBxVcnILNmV4jN5ABVClhxZZBrM'
};
