import { Injectable } from '@angular/core';
import {
  Role, SubscriptionTier,
  Tenant,
  TenantStatus,
  TenantType,
  User,
  UserCreationTrigger,
  UserStatus,
  UserType,
} from 'shared';
import { Auth } from '@angular/fire/auth';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  increment,
  query,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
} from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { from } from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Stripe} from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  authState: any = null;

  constructor(
    private readonly auth: Auth,
    private readonly firestore: Firestore,
    private readonly functions: Functions,
    private readonly authService: AuthService
  ) {}

  /**
   * Returns the shipagile active user. Signed in user's auth claims is used to auto determine the tenant.
   * If you are dealing with signed-out (anaonymous) user, use {@link getGuestUser}. Also see {@link getByEmail}
   * @param {string} docId user id
   *
   */
  async getById(docId: string) {
    const clientId = await this.authService.getTenantId();
    const userDocSnapshot = await getDoc(
      doc(this.firestore, `clients/${clientId}/users/${docId}`)
    );
    return this._mapUserFromFirestore(userDocSnapshot.data());
  }

  /**
   * Returns the shipagile guest user. Tenant Id should be supplied as this cannot be read from the claims due to user
   * not being logged in (anonymous). For actual user, use {@link getById}.
   * @param {string} docId user id
   * @param tenantId
   */
  async getGuestUser(docId: string, tenantId: string) {
    const clientId = tenantId;
    const userDocSnapshot = await getDoc(
      doc(this.firestore, `clients/${clientId}/users/${docId}`)
    );
    return this._mapUserFromFirestore(userDocSnapshot.data());
  }

  /**
   * Returns the shipagile user by their email address.
   * @param {string} email
   */
  async getByEmail(email) {
    const clientId = await this.authService.getTenantId();
    const tenantRef = collection(this.firestore, `clients/${clientId}/users`);
    const userDocSnapshot = await getDocs(
      query(tenantRef, where('emailId', '==', email))
    );

    if (!userDocSnapshot.size) {
      return;
    }
    return this._mapUserFromFirestore(userDocSnapshot.docs[0]);
  }

  /**
   * Returns the firebase user
   * @param {string} email
   */
  async getFirebaseUser(email) {
    return await httpsCallable<{ email }, any>(
      this.functions,
      'getUserByEmail'
    )({ email });
  }

  /**
   * Returns user list
   */
  public async list() {
    const clientId = await this.authService.getTenantId();
    const userQuerySnapshot = await getDocs(
      query(collection(this.firestore, `clients/${clientId}/users`))
    );
    return userQuerySnapshot.docs.map((_doc) =>
      this._mapUserFromFirestore(_doc.data())
    );
  }

  /**
   * Updates the general details of the user record.
   * @param data Change data
   */
  async update(docId: string, data: { fullName?: string; roles?: Role[] }) {
    const updateData: any = {};
    if ('fullName' in data) {
      updateData['fullName'] = data.fullName;
    }
    if ('roles' in data) {
      updateData['roles'] = data.roles;
    }

    if (!Object.keys(updateData).length) {
      console.log('No change data to write to DB');
      return;
    }
    const clientId = await this.authService.getTenantId();
    const userRef = doc(this.firestore, `clients/${clientId}/users/${docId}`);
    await updateDoc(userRef, {
      ...updateData,
      changedAt: serverTimestamp(),
      changedBy: this.auth.currentUser.email,
    });
  }

  /**
   * Returns the logged in user's client record
   */
  async getTenant() {
    const clientId = await this.authService.getTenantId();
    const clientDocSnapshot = await getDoc(
      doc(this.firestore, `clients/${clientId}`)
    );
    return this._mapTenantFromFirestore(clientDocSnapshot.data());
  }

  /**
   * Updates the general details of the user's client.
   * @param {any} data Changed client data
   */
  async updateTenant(data: {
    accountName?: string;
    contactPersonEmail?: string;
    stripeCustomerId?: string;
    stripeCustomerSecret?: string;
  }) {
    const updateData: any = {};
    if ('accountName' in data) {
      updateData['accountName'] = data.accountName;
    }
    if ('contactPersonEmail' in data) {
      updateData['contactPersonEmail'] = data.contactPersonEmail;
    }
    if ('stripeCustomerId' in data) {
      updateData['stripeCustomerId'] = data.stripeCustomerId;
    }
    if ('stripeCustomerSecret' in data) {
      updateData['stripeCustomerSecret'] = data.stripeCustomerSecret;
    }

    if (!Object.keys(updateData).length) {
      console.log('No change data to write to DB');
      return;
    }
    const clientId = await this.authService.getTenantId();
    const clientRef = doc(this.firestore, `clients/${clientId}`);
    await updateDoc(clientRef, {
      ...updateData,
      changedAt: serverTimestamp(),
      changedBy: this?.auth?.currentUser?.email || '',
    });
  }

  /**
   * Creates a guest tenant and adds a guest to the new tenant.
   * @param {UserCreationTrigger} trigger User creation trigger
   * @param {Role[]} roles
   * @param {string} email
   */
  async addGuest(data: {
    trigger: UserCreationTrigger;
    roles: Role[];
    email?: string;
    subsTier?: string;
  }) {
    const batch = writeBatch(this.firestore);

    // Tenant
    const newTenantDocRef = doc(collection(this.firestore, `clients`));

    batch.set(newTenantDocRef, {
      docId: newTenantDocRef.id,
      tenantType: TenantType.guest,
      tenantStatus: TenantStatus.created,
      totalActiveUsers: 0,
      subscriptionTier: data.subsTier || SubscriptionTier.free,
      createdAt: serverTimestamp(),
      createdBy: this.auth?.currentUser?.email || data.email || '',
    });


    const newUserDocRef = doc(
      collection(this.firestore, `clients/${newTenantDocRef.id}/users`)
    );

    batch.set(newUserDocRef, {
      docId: newUserDocRef.id,
      emailId: data.email || '',
      tenantId: newTenantDocRef.id,
      userType: UserType.guest,
      userStatus: UserStatus.created,
      roles: data.roles,
      userCreationTrigger: data.trigger,
      createdAt: serverTimestamp(),
      createdBy: this.auth?.currentUser?.email || data.email || '',
    });

    await batch.commit();
    localStorage.setItem('tenantId', newTenantDocRef.id);
    localStorage.setItem('userId', newUserDocRef.id);
    return {
      tenantId: newTenantDocRef.id,
      userId: newUserDocRef.id,
    };
  }

  /**
   * Adds the invited user as guest into the active tenant of the user who extended the invite.
   * @param {UserCreationTrigger} trigger User creation trigger
   * @param {Role[]} roles
   * @param {string} email
   * @param {string} tenantId
   */
  async addInvitee(data: {
    trigger: UserCreationTrigger;
    roles: Role[];
    email: string;
    tenantId: string;
  }) {
    const batch = writeBatch(this.firestore);

    const newUserDocRef = doc(
      collection(this.firestore, `clients/${data.tenantId}/users`)
    );

    batch.set(newUserDocRef, {
      docId: newUserDocRef.id,
      emailId: data.email || '',
      tenantId: data.tenantId,
      userType: UserType.guest,
      userStatus: UserStatus.Invited,
      roles: data.roles,
      userCreationTrigger: data.trigger,
      createdAt: serverTimestamp(),
      createdBy: this.auth?.currentUser?.email || data.email || '',
    });

    await batch.commit();
    localStorage.setItem('tenantId', data.tenantId);
    localStorage.setItem('userId', newUserDocRef.id);
    return {
      tenantId: data.tenantId,
      userId: newUserDocRef.id,
    };
  }

  /**
   * Updates the guest account (user and tenant both where applicable).
   * @param userId Guest user Id
   * @param tenantId Guest tenant Id
   * @param data Data to update. Must match valid field names in the database
   */
  async updateGuest(
    userId: string,
    tenantId: string,
    data: {
      emailId?: string;
      fullName?: string;
      roles?: Role[];
      contactPersonEmail?: string;
      accountName?: string;
    }
  ) {
    let writeCount = 0;
    const batch = writeBatch(this.firestore);

    let userUpdateData = {};
    userUpdateData = _addIfPresent('emailId', data, userUpdateData);
    userUpdateData = _addIfPresent('fullName', data, userUpdateData);
    userUpdateData = _addIfPresent('roles', data, userUpdateData);

    if (Object.keys(userUpdateData).length) {
      batch.update(doc(this.firestore, `clients/${tenantId}/users/${userId}`), {
        ...userUpdateData,
        changedAt: serverTimestamp(),
        changedBy: this.auth?.currentUser?.email || data.emailId || '',
      });
      writeCount++;
    }

    let tenantUpdateData = {};
    tenantUpdateData = _addIfPresent(
      'contactPersonEmail',
      data,
      tenantUpdateData
    );
    tenantUpdateData = _addIfPresent('accountName', data, tenantUpdateData);

    if (Object.keys(tenantUpdateData).length) {
      batch.update(doc(this.firestore, `clients/${tenantId}`), {
        ...tenantUpdateData,
        changedAt: serverTimestamp(),
        changedBy: this.auth?.currentUser?.email || data.emailId || '',
      });
      writeCount++;
    }

    if (writeCount) {
      await batch.commit();
    }
  }
  /**
   * Converts a GUEST tenant to an ACTIVE tenant and activates the user.
   * @param userId
   * @param tenantId
   */
  async convertGuest(userId: string, tenantId: string) {
    const batch = writeBatch(this.firestore);

    batch.update(doc(this.firestore, `clients/${tenantId}`), {
      tenantType: TenantType.user,
      tenantStatus: TenantStatus.active,
      totalActiveUsers: increment(1),
      createdBy: this.auth.currentUser.email,
      changedAt: serverTimestamp(),
      changedBy: this.auth.currentUser.email,
    });

    batch.update(doc(this.firestore, `clients/${tenantId}/users/${userId}`), {
      firebaseId: this.auth.currentUser.uid,
      emailId: this.auth.currentUser.email,
      userType: UserType.user,
      userStatus: UserStatus.active,
      createdBy: this.auth.currentUser.email,
      changedAt: serverTimestamp(),
      changedBy: this.auth.currentUser.email,
    });

    await batch.commit();
  }

  /**
   * Activate the user after accpeting the invite.
   * @param userId
   * @param tenantId
   */
  async acceptInvite(userId: string, tenantId: string) {
    const batch = writeBatch(this.firestore);

    batch.update(doc(this.firestore, `clients/${tenantId}/users/${userId}`), {
      firebaseId: this.auth.currentUser.uid,
      emailId: this.auth.currentUser.email,
      userType: UserType.user,
      userStatus: UserStatus.active,
      changedAt: serverTimestamp(),
      changedBy: this.auth.currentUser.email,
    });

    batch.update(doc(this.firestore, `clients/${tenantId}`), {
      totalActiveUsers: increment(1),
    });

    await batch.commit();
  }

  /**
   * Returns a typed user record.
   * @param userData User document
   */
  private _mapUserFromFirestore(userData: any): User {
    return {
      docId: userData.docId,
      firebaseId: userData.firebaseId || userData.uid,
      emailId: userData.emailId,
      tenantId: userData.tenantId,
      userType: userData.userType,
      userStatus: userData.userStatus,
      fullName: userData.fullName,
      roles: userData.roles,
      userCreationTrigger: userData.userCreationTrigger,
      createdAt: userData?.createdAt?.toDate(),
      createdBy: userData?.createdBy,
      changedAt: userData?.changedAt?.toDate(),
      changedBy: userData?.changedBy,
    };
  }

  /**
   * Returns a typed client record.
   * @param tenantData Client document
   */
  private _mapTenantFromFirestore(tenantData): Tenant {
    return {
      docId: tenantData.docId,
      tenantType: tenantData.tenantType,
      tenantStatus: tenantData.tenantStatus,
      subscriptionTier: tenantData.subscriptionTier,
      totalActiveUsers: tenantData.totalActiveUsers,
      contactPersonEmail: tenantData.contactPersonEmail,
      stripeCustomerId: tenantData.stripeCustomerId,
      stripeCustomerSecret: tenantData.stripeCustomerSecret,
      accountName: tenantData.accountName,
      createdAt: tenantData?.createdAt?.toDate(),
      createdBy: tenantData?.createdBy,
      changedAt: tenantData?.changedAt?.toDate(),
      changedBy: tenantData?.changedBy,
    };
  }
}

/**
 * Adds the given property from the fromObj to the toObj and return a new object.
 * @param prop The property that needs to be added.
 * @param fromObj The object from which the property should be added.
 * @param toObj The object to which the property shoould be added.
 */
const _addIfPresent = (prop: string, fromObj, toObj: any) => {
  return prop in fromObj ? { ...toObj, [`${prop}`]: fromObj[prop] } : toObj;
};
