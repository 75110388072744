import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Role, UserCreationTrigger, UserStatus, UserType } from "shared";
import { LabelService } from "../core/services/label.service";
import { UserService } from "../core/services/user.service";
import {
  Auth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  browserSessionPersistence,
} from "@angular/fire/auth";
import { Functions, httpsCallable } from "@angular/fire/functions";
import {
  getAdditionalUserInfo,
  UserCredential,
  setPersistence,
} from "@firebase/auth";
import { ShipmentService } from "../core/services/shipment.service";
import { Analytics, logEvent } from "@angular/fire/analytics";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.css"],
})
export class LandingPageComponent implements OnInit {
  constructor(
    private auth: Auth,
    private snackBar: MatSnackBar,
    private router: Router,
    private userService: UserService,
    private labelService: LabelService,
    private shipmentService: ShipmentService,
    private readonly functions: Functions,
    private readonly analytics: Analytics
  ) {}

  email: string;
  emailSent = false;
  errorMessage: string;
  successUrl: string;
  userId: string;
  tenantId: string;

  ngOnInit(): void {
    const params = window.location.href
      .slice(window.location.href.indexOf("?") + 1)
      .split("&");
    this.successUrl = params[0].split("=")[1];
    this.tenantId = params[1].split("=")[1];
    this.userId = params[2].split("=")[1];
    this.confirmSignIn();
  }

  async confirmSignIn() {
    try {
      if (isSignInWithEmailLink(this.auth, window.location.href)) {
        const url = decodeURIComponent(this.successUrl);
        let email = localStorage.getItem("emailForSignIn");
        if (!email && window.location.href.indexOf("oobCode") !== -1) {
          email = window.prompt("Please provide your email for confirmation");
        }

        await setPersistence(this.auth, browserSessionPersistence);

        const result = await signInWithEmailLink(
          this.auth,
          email,
          window.location.href
        );
        if (result.user) {
          if (this.userId && this.tenantId) {
            // sign in
            // check for new User

            const isNewUser = getAdditionalUserInfo(result).isNewUser;
            if (isNewUser) {
              // Fetch the guest user acc. via session ID
              const guestData = await this.userService.getGuestUser(
                this.userId,
                this.tenantId
              );

              if (guestData.userType != UserType.guest) {
                await this.router.navigateByUrl("onboardingError");
                console.log(
                  `Expected user type is GUEST but found a different type`
                );
                this.snackBar.open(
                  `Expected user type is GUEST but found a different type`,
                  "Dismiss"
                );
              }

              switch (guestData.userStatus) {
                case UserStatus.created:
                  await this._completeGuestSignin(
                    result,
                    this.userId,
                    this.tenantId,
                    url,
                    guestData.roles
                  );
                  break;

                case UserStatus.Invited:
                  await this._completeInviteeSignin(
                    result,
                    this.userId,
                    this.tenantId,
                    url,
                    guestData.roles
                  );
                  break;

                default:
                  break;
              }
            } else {
              // fetch the guest user acc via session ID
              const userData = await this.userService.getGuestUser(
                this.userId,
                this.tenantId
              );

              switch (userData.userCreationTrigger) {
                case UserCreationTrigger.promo_link:
                  await this._completeExistingUserSignin(url);
                  break;

                default:
                  logEvent(this.analytics, "sign_in");
                  this.router.navigateByUrl(url);
                  break;
              }
            }
          } else {
            // sign in check new user
            const isNewUser = getAdditionalUserInfo(result).isNewUser;
            if (!isNewUser) {
              // Redirect to
              logEvent(this.analytics, "sign_in");
              this.router.navigateByUrl(url);
            } else {
              this.snackBar.open(
                "All new users MUST have session ID ",
                "close"
              );
              // Redirect standard Onboarding error page
              this.router.navigateByUrl("onboardingError");
              return;
            }
          }
          localStorage.removeItem("emailForSignIn");
        } else {
          // Invalid email
          this.snackBar.open("Invalid email", "close");
          this.router.navigateByUrl("passwordLess");
        }
      } else {
        // Link is expired
        // redirect to password less auth
        this.snackBar.open("The sign-in link has expired", "close");
        this.router.navigateByUrl("passwordLess");
      }
    } catch (error) {
      console.error(error);
      if (error.code === "auth/invalid-action-code") {
        this.router.navigateByUrl("/signinLinkExpiredError");
      }
      this.snackBar.open(error.message, "close");
    }
  }

  private async _completeGuestSignin(
    signedInUserCredential: UserCredential,
    guestUserId: string,
    guestTenantId: string,
    destinationUrl: string,
    roles: Role[] = [Role.admin]
  ) {
    try {
      await httpsCallable(
        this.functions,
        "setCustomUserClaims"
      )({
        uId: signedInUserCredential.user.uid,
        clientId: guestTenantId,
        userId: guestUserId,
        roles: roles,
      });
      await signedInUserCredential.user.getIdToken(true);
      await this.userService.convertGuest(guestUserId, guestTenantId);
      logEvent(this.analytics, "sign_in");
      this.router.navigateByUrl(destinationUrl);
    } catch (error) {
      console.log(`Error signing in guest: ${error}`);
      await this.router.navigateByUrl("onboardingError");
      this.snackBar.open(`Error signing in guest: ${error}`, "Dismiss");
    }
  }

  private async _completeInviteeSignin(
    signedInUserCredential: UserCredential,
    guestUserId: string,
    guestTenantId: string,
    destinationUrl: string,
    roles: Role[]
  ) {
    try {
      await httpsCallable(
        this.functions,
        "setCustomUserClaims"
      )({
        uId: signedInUserCredential.user.uid,
        clientId: guestTenantId,
        userId: guestUserId,
        roles: roles,
      });
      await signedInUserCredential.user.getIdToken(true);
      await this.userService.acceptInvite(guestUserId, guestTenantId);
      logEvent(this.analytics, "sign_in");
      this.router.navigateByUrl(destinationUrl);
    } catch (error) {
      console.log(`Error signing in invitee: ${error}`);
      await this.router.navigateByUrl("onboardingError");
      this.snackBar.open(`Error signing in invitee ${error}`, "Dismiss");
    }
  }

  private async _completeExistingUserSignin(destinationUrl: string) {
    try {
      const shipmentId = destinationUrl.split("/")[2];

      if (!!shipmentId) {
        await this.shipmentService.moveShipmentToCurrentTenant(
          this.tenantId,
          this.userId,
          shipmentId
        );
      }
      logEvent(this.analytics, "sign_in");
      await this.router.navigateByUrl(destinationUrl);
    } catch (error) {
      console.log(`Error signing in user: ${error}`);
      await this.router.navigateByUrl("onboardingError");
      this.snackBar.open(`Error signing in user ${error}`, "Dismiss");
    }
  }
}
