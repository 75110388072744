import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Delivery, Parcel, Rate, Shipment, ShipmentStatus } from "shared";
import firebase from "firebase/compat/app";
import { Unsubscribe } from "@angular/fire/firestore";

export interface ShipmentFilter {
  shipmentStatus?: ShipmentStatus[];
  planShipDate?: { operator: firebase.firestore.WhereFilterOp; value: Date }[];
  orderId?: string;
  deliveryId?: string;
}

@Injectable({
  providedIn: "root",
})
export abstract class ShipmentService {
  abstract list(filter: ShipmentFilter): Observable<Shipment[]>;

  abstract findShipments(options: any): Observable<Shipment[]>;

  abstract subscribeListShipmentsBy(
    options: any,
    listener: Subject<Shipment[]>
  ): Promise<Unsubscribe>;

  abstract subscribeToShipmentUpdates(
    shipmentId: string,
    listener: Subject<Shipment>
  ): Promise<Unsubscribe>;

  abstract subscribeShipmentById(
    id: string,
    listener: Subject<Shipment>
  ): Promise<Unsubscribe>;

  abstract findShipmentById(id: string): Observable<Shipment>;

  abstract findShipmentByClientId(
    clientId: string,
    id: string
  ): Promise<Shipment>;

  abstract createShipment(shipment: Shipment): Promise<string>;

  abstract updatePackedStatus(
    shipmentId: string,
    parcels: Parcel[]
  ): Promise<void>;

  abstract updateShipmentSelectedRate(
    shipmentId: string,
    rate: Rate
  ): Promise<void>;

  abstract cancelBooking(shipmentId: string): Promise<void>;

  abstract emptyRate(shipmentId: string): Promise<void>;

  abstract deleteShipment(shipmentId): Promise<void>;

  abstract moveShipmentToCurrentTenant(
    tenantId: string,
    uid: string,
    shipmentId: string
  ): Promise<void>;

  abstract updateShipmentNotification(
    shipmentId: string,
    notifyRecipient: boolean
  ): Promise<void>;

  abstract update(shipmentId, changes: any): Promise<any>;

  abstract get(shipmentId): Promise<any>;
}
