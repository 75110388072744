import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {SharedDataService} from '../services/shared-data.service';
import {UserService} from '../services/user.service';
import {Auth, user} from '@angular/fire/auth';
import {AuthService} from '../services/auth.service';
import {map, take, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  // isLoggedIn: Observable<boolean>;

  constructor(
    private auth: Auth,
    private data: SharedDataService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return user(this.auth).pipe(
      take(1),
      map(user$ => !!user$),
      tap(isLoggedIn => {
        if (isLoggedIn) {
          console.log('Guard returns true');
          this.data.setRedirectUrl(decodeURIComponent(state.url));
        } else {
          console.log('Guard returns false');
          this.data.setRedirectUrl(decodeURIComponent(state.url));
          this.router.navigate(['/passwordLess']);
        }
      })
    );

    /*     return this.isLoggedIn.pipe(
          take(1),
          tap(val => {
            if (val) {
              console.log("Guard returns true");
            } else {
              console.log("Guard returns false");
              this.data.setRedirectUrl(state.url);
              this.router.navigate(['/login']);
            }
          })
        ); */
    // return this.isLoggedIn;

    /*     if (this.afAuth.auth.currentUser) {
          console.log("Guard returns true "+this.afAuth.auth.currentUser.email);
          return true;
        } else {
          console.log("Guard returns false "+this.afAuth.auth.currentUser.email);
          //Redirect to Login Page and store the attempted URL. After logon user can be routed back to the attempted URL.
          this.data.setRedirectUrl(state.url);
          this.router.navigate(['/login']);
          return false;
        } */
  }
}
