import {CommonModule, DatePipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BUCKET} from '@angular/fire/compat/storage';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxStripeModule} from 'ngx-stripe';
import {environment} from '../environments/environment';
import {AppRoutingModule, routingComponents} from './app-routing.module';
import {AppComponent} from './app.component';
import {CheckEmailComponent} from './check-email/check-email.component';
import {AuthGuard} from './core/guards/auth.guard';
import {FirestoreShipmentService} from './core/services/firestore-shipment.service';
import {SharedDataService} from './core/services/shared-data.service';
import {ShipmentService} from './core/services/shipment.service';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {LoginComponent} from './login/login.component';
import {MaterialModule} from './material.module';
import {PasswordLessComponent} from './password-less/password-less.component';
import {RegisterComponent} from './register/register.component';
import {SharedModule} from './shared/shared.module';
import {TransactionHistoryComponent} from './transaction-history/transaction-history.component';
import {UserProfileDialogComponent} from './user-profile-dialog/user-profile-dialog.component';
import {getApp, initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {
  AnalyticsModule,
  getAnalytics,
  provideAnalytics,
  ScreenTrackingService,
  UserTrackingService
} from '@angular/fire/analytics';
import {
  browserPopupRedirectResolver,
  connectAuthEmulator,
  indexedDBLocalPersistence,
  initializeAuth,
  provideAuth
} from '@angular/fire/auth';
import {connectFirestoreEmulator, getFirestore, provideFirestore} from '@angular/fire/firestore';
import {connectFunctionsEmulator, getFunctions, provideFunctions} from '@angular/fire/functions';
import {FIREBASE_APP_NAME, FIREBASE_OPTIONS} from '@angular/fire/compat';
import {AutofocusDirective} from './directives/autofocus.directive';
import {OnboardingErrorComponent} from './onboarding-error/onboarding-error.component';
import {SigninLinkExpiredErrorComponent} from './signin-link-expired-error/signin-link-expired-error.component';
import {initializeAppCheck, provideAppCheck, ReCaptchaEnterpriseProvider} from '@angular/fire/app-check';

@NgModule({
  declarations: [
    AutofocusDirective,
    AppComponent,
    routingComponents,
    LoginComponent,
    RegisterComponent,
    UserProfileDialogComponent,
    ForgotPasswordComponent,
    PasswordLessComponent,
    CheckEmailComponent,
    LandingPageComponent,
    TransactionHistoryComponent,
    OnboardingErrorComponent,
    SigninLinkExpiredErrorComponent
  ],
  imports: [
    BrowserModule,
    provideFirebaseApp(() => initializeApp({...environment.firebase})),
    provideFirestore(() => {
      const fire = getFirestore();
      if (!environment.production && environment.emulator && environment.firestoreEmulatorPort) {
        connectFirestoreEmulator(fire, 'localhost', environment.firestoreEmulatorPort);
      }
      return fire;
    }),
    provideAuth(() => {
      const auth = initializeAuth(getApp(), {
        persistence: indexedDBLocalPersistence,
        popupRedirectResolver: browserPopupRedirectResolver,
      });
      if (!environment.production && environment.emulator && environment.authEmulatorPort) {
        connectAuthEmulator(auth, `http://${environment.firebase.authDomain}:${environment.authEmulatorPort}`);
      }
      return auth;
    }),
    provideFunctions(() => {
      const functions = getFunctions(getApp(), 'us-central1');
      if (!environment.production && environment.emulator && environment.functionsEmulatorPort) {
        connectFunctionsEmulator(functions, 'localhost', environment.functionsEmulatorPort);
      }
      return functions;
    }),
    provideAnalytics(() => getAnalytics()),
    provideAppCheck(() => {
      if (!environment.production && environment.emulator) {
        (<any>window).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
      }

      return initializeAppCheck(getApp(), {
        provider: new ReCaptchaEnterpriseProvider(environment.recaptchaSiteKey),
        isTokenAutoRefreshEnabled: true
      });
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    FlexLayoutModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    AnalyticsModule,
    CommonModule,
    NgxStripeModule.forRoot(environment.stripeAPIKey)
  ],
  providers: [
    {provide: FIREBASE_OPTIONS, useValue: environment.firebase},
    {provide: FIREBASE_APP_NAME, useValue: 'shipagile'},
    {provide: BUCKET, useValue: 'global-approach-214817.appspot.com'},
    DatePipe,
    {provide: ShipmentService, useClass: FirestoreShipmentService},
    SharedDataService,
    ScreenTrackingService,
    UserTrackingService,
    AuthGuard,
  ],
  exports: [
    AutofocusDirective
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
