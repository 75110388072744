import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {SharedDataService} from '../core/services/shared-data.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserService} from '../core/services/user.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Auth, signInWithEmailAndPassword, user} from '@angular/fire/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
    private auth: Auth,
    private snackBar: MatSnackBar,
    private router: Router,
    private data: SharedDataService,
    protected deviceDetectorService: DeviceDetectorService) {
  }

  signInForm: UntypedFormGroup;

  validations = {
    email: [
      {type: 'required', message: 'Email is required'},
      {type: 'email', message: 'Email is invalid'}
    ],
    password: [
      {type: 'required', message: 'Enter password'}
    ]
  };

  public $isUserLoggedIn: Observable<boolean> = user(this.auth).pipe(map(user$ => !!user$));

  isBtnClick = false;

  ngOnInit() {
    this.confirmSignIn();
    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', Validators.required],
    });
  }

  async confirmSignIn() {
    this.$isUserLoggedIn.subscribe(res => {
      if (res) {
        this.router.navigate(['dashboard']);
      }
    });
  }

  signin(form: UntypedFormGroup) {
    if (form.status === 'VALID') {
      this.isBtnClick = true;
      signInWithEmailAndPassword(this.auth, form.value.email, form.value.password)
        .then(userCredential => {
          console.log(userCredential);
          // Get the attempted URL if coming from the Auth guard. If not route to Dashboard.
          let url: string = this.data.getRedirectUrl();
          url = url ? url : '/dashboard';
          // Reset the attempted URL.
          this.data.setRedirectUrl(null);
          this.router.navigate([decodeURIComponent(url)]);
        })
        .catch(error => {
          this.isBtnClick = false;
          console.log('User Signin Error ' + error);
          let errorMessage: string;
          let action: string;
          switch (error.code) {
            case 'auth/invalid-email':
              errorMessage = 'Email is not valid. Try again';
              break;
            case 'auth/wrong-password':
              errorMessage = 'Incorrect Password. Try again';
              break;
            case 'auth/user-disabled':
              errorMessage = 'User Disabled. Contact admin';
              break;
            case 'auth/user-not-found':
              errorMessage = 'User not found';
              action = 'Create Account';
              break;
            default:
              errorMessage = 'Sign in error. Contact admin';
              break;
          }
          const snackBarRef = this.snackBar.open(errorMessage, action, {duration: 4000});
          snackBarRef.onAction().subscribe(() => {
            if (action === 'Create Account') {
              this.router.navigate(['/', 'passwordLess']);
            }
          });
        });
    }
  }

  onClickOfForgotPassword(url: any) {
    this.router.navigate([url]);
  }

}
