import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nonegativevalue' })
export class NoNegativeValue implements PipeTransform {
  transform(value: number): number {    
    if (value < 0) {      
      value = 1;
      return value;
    }
    return value
  }
}