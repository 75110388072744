
const backDate = (numOfDays: number) => {
  const today = new Date();
  return new Date(today.setDate(today.getDate() - numOfDays));
}

const postDate = (numOfDays: number) => {
  const today = new Date();
  return new Date(today.setDate(today.getDate() + numOfDays));
}

export const TODAY = new Date();
export const TOMORROW = postDate(1);
export const PLUS_7 = postDate(7);
export const PLUS_30 = postDate(30);
export const MINUS_90 = backDate(90);
export const CURRENT_WEEK_START = backDate(TODAY.getDay());
export const CURRENT_WEEK_END = new Date(new Date().setDate(CURRENT_WEEK_START.getDate() + 6));
export const CURRENT_MONTH_START = new Date(TODAY.getFullYear(), TODAY.getMonth(), 1);
export const CURRENT_MONTH_END = new Date(TODAY.getFullYear(), TODAY.getMonth() + 1, 0);
export const NEXT_MONTH_END = new Date(TODAY.getFullYear(), TODAY.getMonth() + 2, 0);
export const PREV_MONTH_END = new Date(TODAY.getFullYear(), TODAY.getMonth(), 0);
export const DATETODAY = Date.now();
export const TODAYMINUS14 = new Date().setDate(TODAY.getDate() - 14);
export const TODAYPLUS7 = new Date().setDate(TODAY.getDate() + 7);


export function days_between(date1) {
  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;
  let today: any = new Date()
  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(date1 - today);
  // Convert back to days and return
  return Math.round(differenceMs / ONE_DAY);
}