import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { Observable, of } from "rxjs";
import { AuthService } from "../core/services/auth.service";

export function emailBlacklistValidator(
  authService: AuthService
): AsyncValidatorFn {
  return (control: AbstractControl): Promise<{ [key: string]: boolean } | null> => {
    const email = control.value;
    return authService
      .isEmailBlacklisted(email)
      .then((isBlacklisted: boolean) => {
        return isBlacklisted ? { emailBlacklist: true } : null;
      });
  };
}
