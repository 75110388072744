import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Action {
  name: string,
  color: string,
}
export interface DialogData {
  title: string,
  supportingText: string,
  actions: Action[],
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<AlertDialogComponent>,
  ) { }

  ngOnInit() {
  }

  onAction(selectedAction: string) {
    this.dialogRef.close(selectedAction);
  }

}
