import {Component, OnInit} from '@angular/core';
import {Auth} from "@angular/fire/auth";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-signin-link-expired-error',
  templateUrl: './signin-link-expired-error.component.html',
  styleUrls: ['./signin-link-expired-error.component.css']
})
export class SigninLinkExpiredErrorComponent implements OnInit {
  reportLink: string;

  constructor(
    private readonly auth: Auth,
    protected deviceDetectorService: DeviceDetectorService
  ) {
  }

  ngOnInit(): void {
    if (this.auth.currentUser) {
      this.reportLink = `https://gn3grycct6u.typeform.com/to/utd4E1rD#email=${this.auth.currentUser.email}`;
      return;
    }
    this.reportLink = `https://gn3grycct6u.typeform.com/to/utd4E1rD`;
  }

  openReportForm() {
    window.open(this.reportLink, '_blank');
  }
}
