import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {Auth, user} from '@angular/fire/auth';

@Component({
  selector: 'app-check-email',
  templateUrl: './check-email.component.html',
  styleUrls: ['./check-email.component.css']
})
export class CheckEmailComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  email: string;

  constructor(private route: ActivatedRoute,
              private auth: Auth,
              private router: Router) {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.email = res.email;
      });
  }


  public $isUserLoggedIn: Observable<boolean> = user(this.auth).pipe(map(user$ => !!user$));

  ngOnInit(): void {
    this.confirmSignIn();
  }

  confirmSignIn() {
    this.$isUserLoggedIn
      .subscribe(res => {
        if (res) {
          this.router.navigate(['dashboard']);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
