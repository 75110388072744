import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private redirectUrl: string;

  constructor() { }

  setRedirectUrl(url: string): void {
    this.redirectUrl = decodeURIComponent(url);
  }

  getRedirectUrl(): string {
    return this.redirectUrl;
  }
}
