import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[saAutofocus]'
})
export class AutofocusDirective {

  @Input()
  saAutofocus: string;

  constructor(private host: ElementRef) {
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    setTimeout(() => this.host.nativeElement.focus());
  }
}
