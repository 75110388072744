import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Auth, user, User} from '@angular/fire/auth';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-user-profile-dialog',
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.css']
})
export class UserProfileDialogComponent implements OnInit {

  constructor(
    public auth: Auth,
    private router: Router,
    private dialog: MatDialog) {
  }

  public loggedUser$: Observable<User> = user(this.auth);

  ngOnInit() {
  }

  signOut() {
    this.dialog.closeAll();
    this.auth.signOut();
    localStorage.removeItem('clientId');
    this.router.navigate(['/passwordLess']);
  }
}
