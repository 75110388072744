import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Auth, sendPasswordResetEmail, user} from '@angular/fire/auth';
import {Functions, httpsCallable} from '@angular/fire/functions';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
    private auth: Auth,
    private functions: Functions,
    private snackBar: MatSnackBar,
    protected deviceDetectorService: DeviceDetectorService,
    private router: Router,
  ) {
  }

  forgotPasswordForm: UntypedFormGroup;
  validations = {
    email: [
      {type: 'required', message: 'Email is required'},
      {type: 'email', message: 'Email is invalid'}
    ]
  };

  public $isUserLoggedIn: Observable<boolean> = user(this.auth).pipe(map(user$ => !!user$));

  isBtnClick = false;

  ngOnInit() {
    this.confirmSignIn();
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  async confirmSignIn() {
    this.$isUserLoggedIn.subscribe(res => {
      if (res) {
        this.router.navigate(['dashboard']);
      }
    });
  }

  forgotPassword(form: UntypedFormGroup) {
    if (form.status === 'VALID') {
      this.isBtnClick = true;

      const actionCodeSettings = {
        url: `${window.location.protocol}//${window.location.host}/landing?successUrl=dashboard`,
        handleCodeInApp: true,
      };
      httpsCallable(this.functions, 'sendResetPasswordLinkEmail')({
        email: form.value.email
      })
        .then(() => {
          this.router.navigateByUrl('checkEmail?email=' + form.value.email);

          // this.snackBar.open(`We have sent you an email with instructions to reset your password.`, 'Close', { duration: 3000 });
        })
        .catch((error) => {
          this.isBtnClick = false;
          console.error(`Error in forgot password: ${error}`);
          this.snackBar.open(`User with this email does not exist`, 'Close');
        });
    }
  }
}
