export function generateShipmentId(): string {
  return getRandomInt(9000000, 10000000).toString() + getRandomChar(2);
}

export function generateCarrierId(): string {
  return getRandomInt(2000000, 3000000).toString() + getRandomChar(2);
}

export function generateOrderId(): string {
  return getRandomInt(4000000, 5000000).toString() + getRandomChar(2);
}

function getRandomInt(min: number, max: number): number {
  const minVal = Math.ceil(min);
  const maxVal = Math.floor(max);
  return Math.floor(Math.random() * (maxVal - minVal)) + minVal;
}

function getRandomChar(len: number): string {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let str = '';
  for (let i = 0; i < len; i++) {
    str += chars.substr(Math.floor(Math.random() * 26), 1);
  }
  return str.trim();
}
