import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Auth} from '@angular/fire/auth';
import {Firestore} from '@angular/fire/firestore';
import {Functions, httpsCallable} from '@angular/fire/functions';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class StripeCustomerService {

    constructor(
        private readonly userService: UserService,
        private readonly auth: Auth,
        private readonly firestore: Firestore,
        private readonly functions: Functions,
        private readonly authService: AuthService
    ) {
    }

    async addStripeCustomer() {
        const tenant = await this.userService.getTenant();
        const currentUser = this.auth.currentUser;
        let stripeCustomerId;
        let stripeCustomerSecret;

        const callableCustomer = httpsCallable(this.functions, 'addStripeCustomer');
        // move to 'next' action for Rate page
        await callableCustomer({
                clientId: tenant.docId,
                name: `custname_${tenant.docId}`,
                email: currentUser?.email || ''
            }
        )
            .then(async res => {
                const stripeCustomer = res.data as any;
                stripeCustomerId = stripeCustomer.id;

                await httpsCallable(this.functions, 'stripeSetupIntent')
                ({clientId: tenant.docId, customerId: stripeCustomer.id}).then(r => {
                    stripeCustomerSecret = (r.data as any).client_secret;
                });
            });

        await this.userService.updateTenant({
            stripeCustomerId: stripeCustomerId,
            stripeCustomerSecret: stripeCustomerSecret
        });

        return {
            stripeCustomerId: stripeCustomerId,
            stripeCustomerSecret: stripeCustomerSecret
        };
    }

    async createNewSetupIntent(clientId: string, customerId: string) {
        const callableSetup = httpsCallable(this.functions, 'stripeSetupIntent');
        return ((await callableSetup({clientId: clientId, customerId: customerId})).data as any).client_secret;
    }
}
