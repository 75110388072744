"use strict";
exports.__esModule = true;
exports.SubscriptionTier = exports.UserCreationTrigger = exports.UserStatus = exports.UserType = exports.TenantStatus = exports.TenantType = exports.Filters = exports.Carriers = exports.CustomEvent = exports.FunctionLogs = exports.ShipmentStatus = exports.CarrierAccType = exports.CarrierAccStatus = exports.MessageType = exports.DeliveryStatus = exports.Priority = exports.OrderStatus = exports.Role = exports.IconType = void 0;
var IconType;
(function (IconType) {
    IconType["info"] = "info";
    IconType["warn"] = "warn";
    IconType["error"] = "error";
    IconType["success"] = "success";
    IconType["disabled"] = "disabled";
})(IconType = exports.IconType || (exports.IconType = {}));
var Role;
(function (Role) {
    Role["admin"] = "ADMIN";
    Role["shipper"] = "SHIPPER";
    Role["view"] = "VIEWER";
})(Role = exports.Role || (exports.Role = {}));
/************************************************************************
 * Order
 ************************************************************************/
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["new"] = "10";
    OrderStatus["shipment_created"] = "20";
    OrderStatus["in_transit"] = "50";
    OrderStatus["complete"] = "90";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
var Priority;
(function (Priority) {
    Priority["hot"] = "0";
    Priority["high"] = "1";
    Priority["medium"] = "2";
    Priority["low"] = "3";
})(Priority = exports.Priority || (exports.Priority = {}));
/************************************************************************
 * Delivery
 ************************************************************************/
var DeliveryStatus;
(function (DeliveryStatus) {
    DeliveryStatus["new"] = "NEW";
    DeliveryStatus["picked"] = "PICKED";
    DeliveryStatus["packed"] = "PACKED";
    DeliveryStatus["pgi"] = "PGI";
})(DeliveryStatus = exports.DeliveryStatus || (exports.DeliveryStatus = {}));
var MessageType;
(function (MessageType) {
    MessageType["error"] = "Error";
    MessageType["warning"] = "Warning";
    MessageType["info"] = "Info";
    MessageType["exception"] = "Exception";
})(MessageType = exports.MessageType || (exports.MessageType = {}));
var CarrierAccStatus;
(function (CarrierAccStatus) {
    CarrierAccStatus["ready"] = "READY_TO_USE";
    CarrierAccStatus["pending"] = "PENDING_ACTIVATION";
    CarrierAccStatus["error"] = "ERROR_ACTIVATING";
})(CarrierAccStatus = exports.CarrierAccStatus || (exports.CarrierAccStatus = {}));
var CarrierAccType;
(function (CarrierAccType) {
    CarrierAccType["master"] = "master";
    CarrierAccType["client"] = "client";
})(CarrierAccType = exports.CarrierAccType || (exports.CarrierAccType = {}));
/************************************************************************
 * Shipment
 ************************************************************************/
var ShipmentStatus;
(function (ShipmentStatus) {
    ShipmentStatus["new"] = "NEW";
    ShipmentStatus["packed"] = "PACKED";
    ShipmentStatus["booking_initiated"] = "BOOKING_INITIATED";
    ShipmentStatus["label_generated"] = "LABEL_GENERATED";
    ShipmentStatus["pre_transit"] = "PRE_TRANSIT";
    ShipmentStatus["in_transit"] = "IN_TRANSIT";
    ShipmentStatus["delivered"] = "DELIVERED";
    ShipmentStatus["returned"] = "RETURNED";
    ShipmentStatus["failure"] = "FAILURE";
    ShipmentStatus["unknown"] = "UNKNOWN";
    ShipmentStatus["refunded"] = "REFUNDED";
    ShipmentStatus["pending_refund"] = "PENDING_REFUND";
    ShipmentStatus["cancelled"] = "CANCELLED";
})(ShipmentStatus = exports.ShipmentStatus || (exports.ShipmentStatus = {}));
/************************************************************************
 * Logging
 ************************************************************************/
var FunctionLogs;
(function (FunctionLogs) {
    FunctionLogs["SUCCESS"] = "log";
    FunctionLogs["INFO"] = "info";
    FunctionLogs["WARN"] = "warn";
    FunctionLogs["ERROR"] = "error";
})(FunctionLogs = exports.FunctionLogs || (exports.FunctionLogs = {}));
//Used in updating the custom event name in the logs. To be used in Log Analysis.
//The naming convention is <noun>_<verb> where noun is any entity like USER, SHIPMENT, ORDER, etc.
//Verb is CREATE, READ, UPDATE, DELETE or any custom action if it does not fit in to CRUD.
//Example: USER_CREATE, ORDER_UPDATE, SHIPMENT_TRACK.
var CustomEvent;
(function (CustomEvent) {
    CustomEvent[CustomEvent["CLIENT_CREATE"] = 0] = "CLIENT_CREATE";
    CustomEvent[CustomEvent["USER_CREATE"] = 1] = "USER_CREATE";
    CustomEvent[CustomEvent["CLAIMS_UPDATE"] = 2] = "CLAIMS_UPDATE";
    CustomEvent[CustomEvent["TRACKING_UPDATE"] = 3] = "TRACKING_UPDATE";
    CustomEvent[CustomEvent["TRACKING_CREATE"] = 4] = "TRACKING_CREATE";
    CustomEvent[CustomEvent["STRIPE_CUSTOMER_CREATE"] = 5] = "STRIPE_CUSTOMER_CREATE";
    CustomEvent[CustomEvent["SETUP_INTENT_CREATE"] = 6] = "SETUP_INTENT_CREATE";
    CustomEvent[CustomEvent["PAYMENT_METHOD_READ"] = 7] = "PAYMENT_METHOD_READ";
    CustomEvent[CustomEvent["PAYMENT_METHOD_DELETE"] = 8] = "PAYMENT_METHOD_DELETE";
    CustomEvent[CustomEvent["DEFUALT_PAYMENT_METHOD_UPDATE"] = 9] = "DEFUALT_PAYMENT_METHOD_UPDATE";
    CustomEvent[CustomEvent["SIGN_EMAIL_SEND"] = 10] = "SIGN_EMAIL_SEND";
    CustomEvent[CustomEvent["USER_DELETE"] = 11] = "USER_DELETE";
    CustomEvent[CustomEvent["COST_BY_SERVICE_CARRIER_READ"] = 12] = "COST_BY_SERVICE_CARRIER_READ";
    CustomEvent[CustomEvent["WEEKLY_TOTAL_SHIPPING_COST_READ"] = 13] = "WEEKLY_TOTAL_SHIPPING_COST_READ";
    CustomEvent[CustomEvent["COST_BY_DESTINATION_READ"] = 14] = "COST_BY_DESTINATION_READ";
    CustomEvent[CustomEvent["COUNT_BY_STATUS_READ"] = 15] = "COUNT_BY_STATUS_READ";
    CustomEvent[CustomEvent["ORDER_DUES_READ"] = 16] = "ORDER_DUES_READ";
    CustomEvent[CustomEvent["TOTAL_SHIPMENT_COST_READ"] = 17] = "TOTAL_SHIPMENT_COST_READ";
    CustomEvent[CustomEvent["INITIATE_BOOKING_UPDATE"] = 18] = "INITIATE_BOOKING_UPDATE";
    CustomEvent[CustomEvent["LABLE_LINK_EMAIL"] = 19] = "LABLE_LINK_EMAIL";
    CustomEvent[CustomEvent["TRANSACTION_CREATE"] = 20] = "TRANSACTION_CREATE";
    CustomEvent[CustomEvent["SHIP_NOTIFICATION_EMAIL"] = 21] = "SHIP_NOTIFICATION_EMAIL";
    CustomEvent[CustomEvent["TRANSACTION_UPDATE"] = 22] = "TRANSACTION_UPDATE";
})(CustomEvent = exports.CustomEvent || (exports.CustomEvent = {}));
var Carriers;
(function (Carriers) {
    Carriers["ups"] = "UPS";
    Carriers["usps"] = "USPS";
    Carriers["fedex"] = "FedEx";
    Carriers["dhl"] = "DHL";
})(Carriers = exports.Carriers || (exports.Carriers = {}));
var Filters;
(function (Filters) {
    Filters["_7D"] = "7 DAY";
    Filters["_1M"] = "1 MONTH";
    Filters["_6M"] = "6 MONTH";
    Filters["_YTD"] = "YTD";
    Filters["_1Y"] = "1 YEAR";
    Filters["_5Y"] = "5 YEAR";
})(Filters = exports.Filters || (exports.Filters = {}));
/************************************************************************
 * Tenent Id
 ************************************************************************/
var TenantType;
(function (TenantType) {
    TenantType["guest"] = "GUEST";
    TenantType["user"] = "USER";
})(TenantType = exports.TenantType || (exports.TenantType = {}));
var TenantStatus;
(function (TenantStatus) {
    TenantStatus["created"] = "CREATED";
    TenantStatus["active"] = "ACTIVE";
    TenantStatus["suspended"] = "SUSPENDED";
    TenantStatus["obsolete"] = "OBSOLETE";
})(TenantStatus = exports.TenantStatus || (exports.TenantStatus = {}));
// export interface Tenant {
//     docId: string,  //Autogenerated document Id.
//     tenantType: TenantType,
//     tenantStatus: TenantStatus,
//     totalUserCount: number,
//     createdAt: Date,
//     createdBy: string //Original or the first user who created the tenant.
// }
var UserType;
(function (UserType) {
    UserType["guest"] = "GUEST";
    UserType["user"] = "USER";
})(UserType = exports.UserType || (exports.UserType = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus["created"] = "CREATED";
    UserStatus["Invited"] = "INVITED";
    UserStatus["active"] = "ACTIVE";
    UserStatus["suspended"] = "SUSPENDED";
    UserStatus["duplicate"] = "DUPLICATE";
})(UserStatus = exports.UserStatus || (exports.UserStatus = {}));
var UserCreationTrigger;
(function (UserCreationTrigger) {
    UserCreationTrigger["promo_link"] = "PROMO_LINK";
    UserCreationTrigger["invitation"] = "INVITATION";
    UserCreationTrigger["self_sign_up"] = "SELF_SIGN_UP";
})(UserCreationTrigger = exports.UserCreationTrigger || (exports.UserCreationTrigger = {}));
var SubscriptionTier;
(function (SubscriptionTier) {
    SubscriptionTier["free"] = "free";
    SubscriptionTier["value"] = "value";
    SubscriptionTier["pro"] = "pro";
})(SubscriptionTier = exports.SubscriptionTier || (exports.SubscriptionTier = {}));
