import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {AuthGuard} from './core/guards/auth.guard';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {PasswordLessComponent} from './password-less/password-less.component';
import {CheckEmailComponent} from './check-email/check-email.component';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {OnboardingErrorComponent} from './onboarding-error/onboarding-error.component';
import {TransactionHistoryComponent} from './transaction-history/transaction-history.component';
import {SigninLinkExpiredErrorComponent} from './signin-link-expired-error/signin-link-expired-error.component';

const routes: Routes = [
  {path: 'landing', component: LandingPageComponent},
  {path: 'passwordLess', component: PasswordLessComponent},
  {path: 'checkEmail', component: CheckEmailComponent},
  {path: 'onboardingError', component: OnboardingErrorComponent},
  {path: 'signinLinkExpiredError', component: SigninLinkExpiredErrorComponent},
  {
    path: 'shipments',
    loadChildren: () => import('./features/shipment/shipment.module').then(m => m.ShipmentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {path: 'label', loadChildren: () => import('./features/create-label/label.module').then(m => m.LabelModule)},
  {
    path: 'setting',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard]
  },
  {path: 'transactionHistory', component: TransactionHistoryComponent},
  // Wild Card Route for 404 request
  { path: '**', pathMatch: 'full', redirectTo: 'onboardingError'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

export const routingComponents = [
  LoginComponent,
  RegisterComponent,
];
