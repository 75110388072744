import {Injectable} from '@angular/core';
import {PaymentMethod} from 'shared';
import {UserService} from './user.service';
import {collection, doc, Firestore, getDocs, onSnapshot, setDoc, Unsubscribe, updateDoc, where, writeBatch} from '@angular/fire/firestore';
import {Subject} from 'rxjs';
import {query} from 'firebase/firestore';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    constructor(
        private firestore: Firestore,
        private userService: UserService,
        private authService: AuthService,
    ) {
    }

    async delete(paymentMethodId) {
        const clientId = await this.authService.getTenantId();
        return updateDoc(doc(this.firestore, `clients/${clientId}/payment-methods/${paymentMethodId}`), {isDeleted: true});
    }

    async setAsDefault(defaultPayment, nonDefaultPayments) {
        const clientId = await this.authService.getTenantId();
        const batch = writeBatch(this.firestore);
        nonDefaultPayments.forEach(payment => {
            if (payment.paymentMethodId !== defaultPayment.paymentMethodId) {
                batch.update(doc(this.firestore, `clients/${clientId}/payment-methods/${payment.paymentMethodId}`), {
                    isPrimary: false
                });
            }
        });
        batch.update(doc(this.firestore, `clients/${clientId}/payment-methods/${defaultPayment.paymentMethodId}`), {
            isPrimary: true
        });
        return batch.commit();
    }

    /**
     * Save payment method into database
     */
    async create(obj) {
        const payment = {} as PaymentMethod;
        payment.createdBy = '';
        payment.createdOn = new Date();
        payment.isDeleted = false;
        payment.isActive = true;
        payment.isPrimary = obj.isPrimary;
        payment.stripeClientSecret = obj?.client_secret;
        const clientId = await this.authService.getTenantId();

        const collectionRef = collection(this.firestore, `clients/${clientId}/payment-methods`);

        // Generate "locally" a new document for the given collection reference
        const docRef = doc(collectionRef);

        // Get the new document ID
        payment.paymentMethodId = docRef.id;

        return setDoc(doc(this.firestore, `clients/${clientId}/payment-methods/${payment.paymentMethodId}`), payment);
    }

    /**
     * get payment method.
     */
    public async list() {
        const tenantId = await this.authService.getTenantId();
        const paymentsQuerySnapshot = await getDocs(query(collection(this.firestore, `clients/${tenantId}/payment-methods`)));
        return paymentsQuerySnapshot.docs.map(_doc => this._fromFirestore(_doc.data()));
    }

    public async subscribeFindAllPaymentMethods(listener: Subject<PaymentMethod[]>): Promise<Unsubscribe> {
        const tenantId = await this.authService.getTenantId();

        const whereIsDeletedFalse = where('isDeleted', '==', false);
        const queryCollection = query(collection(this.firestore, `clients/${tenantId}/payment-methods`), whereIsDeletedFalse);

        return onSnapshot(queryCollection, (querySnapshot) => {
            listener.next(querySnapshot.docs.map(_doc => this._fromFirestore(_doc.data())));
        });
    }

    private _fromFirestore(data): PaymentMethod {
        return {
            stripeClientSecret: data.stripeClientSecret,
            paymentMethodId: data.paymentMethodId,
            isPrimary: data.isPrimary,
            isDeleted: data.isDeleted,
            isActive: data.isActive,
            createdBy: data.createdBy,
            createdOn: data.createdOn,
            changedBy: data.changedBy,
            changedOn: data.changedOn,
        };
    }
}
