import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  Functions,
  httpsCallable,
  HttpsCallableResult,
} from "@angular/fire/functions";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { Role, SubscriptionTier, UserCreationTrigger } from "shared";
import { UserService } from "../core/services/user.service";
import { Auth, user } from "@angular/fire/auth";
import { DeviceDetectorService } from "ngx-device-detector";
import { emailBlacklistValidator } from "../validators/email-blacklist.validator";
import { AuthService } from "../core/services/auth.service";

@Component({
  selector: "app-password-less",
  templateUrl: "./password-less.component.html",
  styleUrls: ["./password-less.component.css"],
})
export class PasswordLessComponent implements OnInit {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    protected deviceDetectorService: DeviceDetectorService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private auth: Auth,
    private readonly functions: Functions,
    private authService: AuthService
  ) {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.subscriptionTier = res.plan || SubscriptionTier.free;
    });
  }

  @ViewChild("email") emailElement: ElementRef;

  public subscriptionTier = SubscriptionTier.free;
  public signInForm: UntypedFormGroup;
  public isBtnClick = false;

  public $isUserLoggedIn: Observable<boolean> = user(this.auth).pipe(
    map((user$) => !!user$)
  );

  public validations = {
    email: [
      { type: "required", message: "Email is required" },
      { type: "email", message: "Not a valid email" },
      {
        type: "emailBlacklist",
        message: "Try your work email or a different email address",
      },
    ],
  };

  private email: string;

  ngOnInit(): void {
    this.confirmSignIn();
    this.signInForm = this.fb.group(
      {
        email: [
          "",
          [Validators.required, Validators.email],
          emailBlacklistValidator(this.authService),
        ],
      },
      { updateOn: "submit" }
    );

    // this.signInForm
    //   .get("email")
    //   .valueChanges.pipe(
    //     debounceTime(500),
    //     distinctUntilChanged(),
    //     tap((value) => console.log("Checking to validate", value)),
    //     filter((value) => this.signInForm.get("email").valid),
    //     tap((value) => console.log("validating blacklist", value)),
    //     switchMap((value) => this.authService.isEmailBlacklisted(value)),
    //     tap((isBlacklisted) => {
    //       console.log("isBlacklisted", isBlacklisted);
    //       if (isBlacklisted) {
    //         this.signInForm.get("email").setErrors({ emailBlacklist: true });
    //       }
    //     })
    //   )
    //   .subscribe();
  }

  async confirmSignIn() {
    this.$isUserLoggedIn.subscribe((res) => {
      if (res) {
        this.router.navigate(["dashboard"]);
      }
    });
  }

  async signIn() {
    try {
      if (!this.signInForm.valid) {
        this.emailElement.nativeElement.focus();
        return;
      }
      this.isBtnClick = true;
      const value = this.signInForm.value;
      const firebaseUserResult = await this.userService.getFirebaseUser(
        value.email
      );

      let userId = "";
      let tenantId = "";

      if (this.isNotExistingUser(firebaseUserResult)) {
        const guestUser = await this.userService.addGuest({
          trigger: UserCreationTrigger.self_sign_up,
          roles: [Role.admin],
          email: value.email,
          subsTier: this.subscriptionTier,
        });

        userId = guestUser.userId;
        tenantId = guestUser.tenantId;
      }

      localStorage.setItem("emailForSignIn", value.email);

      await httpsCallable(
        this.functions,
        "signInEmail"
      )({
        email: value.email,
        clientId: tenantId,
        userId: userId,
      });
      this.router.navigateByUrl("checkEmail?email=" + value.email);
    } catch (error) {
      this.isBtnClick = false;
      this._snackBar.open(`Something went wrong: ${error}`, "Dismiss");
      console.error(error);
    }
  }

  private isNotExistingUser(firebaseUserResult: HttpsCallableResult<any>) {
    return !firebaseUserResult.data?.uid;
  }
}
