import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { Shipment } from "shared";
import { ShipmentService } from "src/app/core/services/shipment.service";
import { SharedDataService } from "../core/services/shared-data.service";

enum TrackingStatus {
  pre_transit = "PRE_TRANSIT",
  transit = "TRANSIT",
  delivered = "DELIVERED",
  returned = "RETURNED",
  failure = "FAILURE",
  unknown = "UNKNOWN",
}

@Component({
  selector: "app-transaction-history",
  templateUrl: "./transaction-history.component.html",
  styleUrls: [
    "./transaction-history.component.css",
    "../features/shipment/shipment-detail/shipment-detail.component.css",
  ],
})
export class TransactionHistoryComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private shipmentServive: ShipmentService,
    private data: SharedDataService,
    private _snackBar: MatSnackBar
  ) {}

  @Input()
  public shipment: Shipment;
  public trackingStatus = TrackingStatus;

  private destroy$ = new Subscription();

  step = 1;

  ngOnInit(): void {
    this.data.setRedirectUrl(this.route.snapshot.url[0].path);
    const shipmentId = this.route.snapshot.queryParams.shipmentId;
    const clientId = this.route.snapshot.queryParams.clientId;
    this.shipmentServive
      .findShipmentByClientId(clientId, shipmentId)
      .then((shipment) => {
        this.shipment = shipment;
      });
    // this.destroy$ = this.shipmentServive.findShipmentByClientId(clientId, shipmentId).subscribe(shipment => {
    //   this.shipment = shipment;
    // });
  }

  copyDetails() {
    if (this.shipment.carrierTrackingId) {
      this._snackBar.open("Copied to clipboard", "Close", { duration: 3000 });
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  ngOnDestroy() {
    this.destroy$.unsubscribe();
  }
}
