import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IconType } from 'shared';

export interface LabelConfig {
  state: IconType,
  isIcon?: boolean,
  isMenu?: boolean
}

export interface LabelAction {
  name: string,
  displayName: string,
  tooltip?: string,
  icon: string,
  type?: "normal" | "primary" | "accent" | "warn",
}

@Component({
  selector: 'sa-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css']
})
export class LabelComponent implements OnInit {

  @Input()
  text: string;

  @Input()
  icon: string;

  @Input()
  tooltip: string;

  @Input()
  config: LabelConfig;

  @Input()
  actions?: LabelAction[];

  @Output()
  action: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onRowAction(action: string) {
    this.action.emit(action);
  }
}
