import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Appearance, StripeElementsOptions} from '@stripe/stripe-js';
import {StripePaymentElementComponent, StripeService} from 'ngx-stripe';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PaymentService} from 'src/app/core/services/payment.service';
import {UserService} from 'src/app/core/services/user.service';
import {Auth} from '@angular/fire/auth';
import {Functions, httpsCallable} from '@angular/fire/functions';
import {AuthService} from 'src/app/core/services/auth.service';
import {StripeCustomerService} from '../../core/services/stripe-customer.service';

export interface DialogData {
    email: string;
    isUpdated: boolean;
}

@Component({
    selector: 'app-capture-payment',
    templateUrl: './capture-payment.component.html',
    styleUrls: ['./capture-payment.component.css']
})
export class CapturePaymentComponent implements OnInit, OnDestroy {

    constructor(
        private auth: Auth,
        private stripeService: StripeService,
        private fb: UntypedFormBuilder,
        private userService: UserService,
        private stripeCustomerService: StripeCustomerService,
        private paymentService: PaymentService,
        private _snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private functions: Functions,
        public dialogRef: MatDialogRef<MatDialog>,
        private authService: AuthService,
    ) {
    }

    private destroy$: Subject<boolean> = new Subject<boolean>();

    email: string;

    @ViewChild(StripePaymentElementComponent)
    paymentElement: StripePaymentElementComponent;

    elementsOptions: StripeElementsOptions = {
        locale: 'en',
    };


    appearance: Appearance = {
        theme: 'stripe',
        labels: 'floating',
    };

    paymentMethods = [];

    isPrimary = false;
    close = false;
    paying = false;

    async ngOnInit() {
        this.email = this.auth.currentUser?.email ? this.auth.currentUser?.email : this.data.email;
        try {
            const tenant = await this.userService.getTenant();
            let stripeCustomerId = tenant.stripeCustomerId;
            if (!stripeCustomerId) {
                const result = await this.stripeCustomerService.addStripeCustomer();
                stripeCustomerId = result.stripeCustomerId;
            }
            const clientId = await this.authService.getTenantId();
            const clientSecrete = await this.stripeCustomerService.createNewSetupIntent(clientId, stripeCustomerId);
            if (clientSecrete) {
                this.elementsOptions.clientSecret = clientSecrete;
            } else {
                this._snackBar.open('Something went wrong', 'close');
            }
            const payments = await this.paymentService.list();
            if (payments.length <= 0) {
                this.isPrimary = true;
            }
        } catch (error) {
            console.error(error);
            this._snackBar.open(`Error setting up payment: ${error}`, 'close');
        }
    }

    savePaymentMethod() {
        if (!this.paymentElement?.elements) {
            return;
        }
        this.paying = true;
        this.stripeService
            .confirmSetup({
                elements: this.paymentElement.elements,
                confirmParams: {
                    payment_method_data: {
                        billing_details: {
                            name: '',
                            email: this.email,
                        },
                    },
                },
                redirect: 'if_required',
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: async (result) => {
                    this.paying = false;
                    if (result.error) {
                        this._snackBar.open(result.error.message, 'close');
                    } else if (result.setupIntent.status === 'succeeded') {
                        this.close = true;
                        this.data.isUpdated = true;
                        this.dialogRef.close(this.data);
                        const obj = {} as any;
                        obj.client_secret = result.setupIntent.client_secret;
                        obj.isPrimary = this.isPrimary;
                        if (this.isPrimary) {
                            this.setPaymentDefault(result.setupIntent.payment_method);
                        }
                        this.paymentService.create(obj);
                        this._snackBar.open('Payment method setup correctly', 'close', {
                            duration: 3000
                        });
                    }
                },
                error: (err) => {
                    this.paying = false;
                    this._snackBar.open(err.message || 'Unknown Error', 'close');
                },
            });
    }

    setPaymentDefault(paymentMethodId) {
        this.userService.getTenant().then(tenant => {
            httpsCallable(this.functions, 'saveDefaultPaymentMethod')({
                paymentMethodId: paymentMethodId,
                customerId: tenant.stripeCustomerId
            }).then();
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}


