import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { MaterialModule } from '../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SpinnerComponent } from './spinner/spinner.component';
import { LabelComponent } from './label/label.component';
import { NoNegativeValue } from './NoNegativeValue';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { CapturePaymentComponent } from './capture-payment/capture-payment.component';
import { FormsModule } from '@angular/forms';
import { DeletePaymentComponent } from './delete-payment/delete-payment.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        NgxStripeModule.forRoot(environment.stripeAPIKey),
        FormsModule
    ],
    declarations: [
        AlertDialogComponent,
        SpinnerComponent,
        LabelComponent, NoNegativeValue,
        CapturePaymentComponent,
        DeletePaymentComponent
    ],
    exports: [
        SpinnerComponent,
        LabelComponent,
        NoNegativeValue,
    ]
})
export class SharedModule { }
